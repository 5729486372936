import {RoutingPath} from '../routes/routing-path';

export const breadcrumbParts = {
  isp: {
    home: {
      label: 'HOME',
      route: RoutingPath.ispPortal,
    },
    companyList: {
      label: '企業一覧',
      route: RoutingPath.ispCompanyList,
    },
    companyDetail: {
      label: ' ',
      route: RoutingPath.ispCompanyDetail,
    },
    companyRegister: {
      label: '新規企業登録',
      route: RoutingPath.ispCompanyAdd,
    },
    companyEdit: {
      label: '企業登録変更',
      route: RoutingPath.ispCompanyEdit,
    },
    companyStatus: {
      label: '状況一覧',
      route: RoutingPath.ispCompanyStatus,
    },
    organizationManage: {
      label: '組織管理',
      route: RoutingPath.ispOrgManagement,
    },
    companyUserList: {
      label: '企業ユーザー管理',
      route: RoutingPath.ispCompanyUserList
    },
    companyUserAdd: {
      label: 'ユーザー新規登録',
      route: RoutingPath.ispCompanyInvitationUser,
    },
    companyUserEdit: {
      label: 'ユーザー情報編集',
      route: RoutingPath.ispCompanyUserEdit,
    },
    ispUserList: {
      label: 'ISPユーザー一覧',
      route: RoutingPath.ispUserList,
    },
    ispUserRegister: {
      label: 'ISPユーザー登録',
      route: RoutingPath.ispInvitationUser,
    },
    ispUserUpdate: {
      label: 'ISPユーザー更新',
      route: RoutingPath.ispUserEdit,
    },
    ispInfo: {
      label: 'インフォメーション一覧',
      route: RoutingPath.ispInfo,
    },
    ispInfoDetail: {
      label: 'インフォメーション詳細',
      route: RoutingPath.ispInfoDetail,
    },
    ispInfoAdd: {
      label: 'インフォメーション新規',
      route: RoutingPath.ispInfoAdd,
    },
    ispInfoSelect: {
      label: '通知先設定',
      route: RoutingPath.ispInfoSelect,
    },
    ispLog: {
      label: '企業操作ログ',
      route:RoutingPath.ispLogExport,
    },
  },
  company: {
    home: {
      label: 'HOME',
      route: RoutingPath.companyStatus,
    },
    organizationManage: {
      label: '組織管理',
      route: RoutingPath.companyOrgManagement,
    },
    userList: {
      label: 'ユーザー一覧',
      route: RoutingPath.companyUserList
    },
    userAdd: {
      label: 'ユーザー新規登録',
      route: RoutingPath.companyInvitationUser,
    },
    userEdit: {
      label: 'ユーザー情報編集',
      route: RoutingPath.companyUserEdit,
    },
    userSetting: {
      label: 'ユーザー設定',
      route: RoutingPath.companyUserSetting,
    },
    editPassword: {
      label: 'パスワード変更',
      route: RoutingPath.companyEditPassword,
    },
    info: {
      label: 'インフォメーション一覧',
      route: RoutingPath.companyInfo,
    },
    infoDetail: {
      label: 'インフォメーション詳細',
      route: RoutingPath.companyInfoDetail,
    },
    infoAdd: {
      label: 'インフォメーション新規',
      route: RoutingPath.companyInfoAdd,
    },
    infoSelect: {
      label: '通知先設定',
      route: RoutingPath.ispInfoSelect,
    },

    ispLog: {
      label: '操作ログ',
      route:RoutingPath.companyLogExport,
    },
  },
};
