import { OutputData } from '@editorjs/editorjs';
import { useEffect, useMemo, useRef, useState } from 'react';
import { uuid } from 'uuidv4';
import { useEditorJs } from '../../../hooks/editor-js';
import { Validation } from '../../../managers/validation/validation';
import { createPlaneText } from '../../../utilities/create-plane-text';
import { Tooltip } from '../tooltip/tooltip';
import './rich-text.scss';

type Props = {
  callback: (e: OutputData) => void,
  initData?: OutputData,
  isReadOnly?: boolean,
  validation?: Validation[],
  isAdd?: boolean,
  isEdit?: boolean,
  edgeEle?: globalThis.Window | HTMLElement,
  maxOption?: { towEle: HTMLElement, offset?: number },
  isDialog?: boolean,
}

const dummyData: OutputData = {
  blocks: [{
    data: {
      text: '&nbsp;'
    },
    id: "x-bWFeDxJB",
    type: "paragraph",
  }],
}

export const RichText = (props: Props) => {
  const { isAdd, callback, initData, isReadOnly, validation, isEdit, edgeEle, maxOption, isDialog=true } = props;
  const ref = useRef<HTMLDivElement>(null);
  const bodyRef = useRef<HTMLDivElement>(null);
  const wrapRef = useRef<HTMLDivElement>(null)
  const [isInit, setIsInit] = useState(false);
  const [_uuid] = useState(uuid());
  const [planeBody, setPlaneBody] = useState('');
  const [isViewValid, setIsViewValid] = useState(false);
  const [focused, setFocused] = useState(false);
  const [enter, setEnter] = useState(false);
  const [isDummy, setIsDummy] = useState(false);
  const [width, setWidth] = useState<number>();
  const [isInit2, setIsInit2] = useState(false);
  const [isLoad, setIsLoad] = useState(false);


  const editor = useEditorJs(ref, (e) => {
    callback(e);
    setPlaneBody(createPlaneText(e));
  }, {
    initData,
    isReadOnly,
  });

  const valid = useMemo(() => {
    const result: string[] = [];
    validation?.forEach((v) => {
      if (!v.test(planeBody)) {
        result.push(...v.errorMessages);
      }
    });
    return result;
  }, [validation, planeBody])

  useEffect(() => {
    if (isAdd) return;
    if (isInit || !initData || !editor.current) return;
    if (!initData.blocks.length) return;
    setIsInit(true);
    editor.current.isReady.then(() => {
      setPlaneBody(createPlaneText(initData));
      editor.current?.render(initData).then(() => {
        setIsLoad(true);
      });
    });
  }, [editor.current, initData])

  useEffect(() => {
    editor.current?.isReady.then(() => {
      if (!isLoad && !initData) {
        setIsLoad(true);
      }
      if (planeBody) {
        editor.current?.readOnly?.toggle(isReadOnly).then(() => {
          if (isDummy) {
            setIsDummy(false);
          }
        });
      }
      else {
        if (isReadOnly) {
          setIsDummy(true);
          editor?.current?.render(dummyData).then(() => {
            editor.current?.readOnly?.toggle(isReadOnly);
          })
        } else {
          if (editor.current?.readOnly.isEnabled !== !!isReadOnly) {
            editor.current?.readOnly?.toggle(isReadOnly).then(() => {
              if (isDummy) {
                editor.current?.clear();
              }
            });
          }
        }
      }
    });
  }, [isReadOnly]);

  useEffect(() => {
    return (() => {
      editor.current?.destroy();
    })
  }, [])

  useEffect(() => {
    if (!maxOption || !isLoad) return;
    if (isDialog && isInit2) return;
    if (isDialog && !isInit2) {
      setIsInit2(true);
    }
    const towEleObserver = new ResizeObserver((entries) => {
      const towWidth = entries[0].contentRect.width + (maxOption?.offset || 0);
      setWidth(towWidth);
      towEleObserver.disconnect();
    });
    towEleObserver.observe(maxOption.towEle);
  
  }, [maxOption, isLoad]);

  return (
    <div className="rich_text__wrap" ref={wrapRef}>
      <div
        className={`rich_text${isEdit ? ' edit' : ''}`}
        onFocus={() => {
          setIsViewValid(true);
          setFocused(true);
        }}
        onClick={(e) => {
          if ((e.target as any).contentEditable === 'inherit' && !(e.target as any).isContentEditable && !(e.target as any).target?.id && !(e.target as any)?.pluginType) {
            editor.current?.caret.setToLastBlock('end');
          }
        }}
        onMouseEnter={() => setEnter(true)}
        onMouseLeave={() => setEnter(false)}
        onBlur={() => setFocused(false)}
        style={{
          borderColor: (isViewValid && !!valid.length) ? '#E35D67' : undefined, // #E35D67 .... $error_text_color
          width
        }}
        ref={bodyRef}
      >
        <div id={_uuid} className="rich_text__inner" ref={ref} />
      </div>
      {(isViewValid && !!valid.length && (focused || enter)) &&
        <Tooltip
          relativeEle={wrapRef.current}
          content={<>
            {valid.map((message, i) => (
              <div key={`invalid_message_${i}`} >{message}</div>
            ))}
          </>}
          autoPos={{ h: "left", v: "top" }}
          blowing
          error
          positionType="absolute"
          edgeSupport={edgeEle && { ele: edgeEle }}
        />
      }
    </div>
  )
}