import React, { useState, useCallback, useEffect, useRef, useMemo } from 'react';
import { Header } from '../../ui/header/header';
import { SideBar } from '../../ui/sidebar/sidebar';
import { Input } from '../../ui/input/input';
import { Button } from '../../ui/button/button';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { RoutingPath } from '../../../routes/routing-path';
import { ZeroPadding } from '../../../utilities/zero-padding-formatter';
import { PageComponentDefaultProps } from '../../../models/page-component-default-props';
import { Select } from '../../ui/select/select';
import { prefectureList } from '../../../models/prefecture-list';
import { breadcrumbParts } from '../../../models/breadcrumb-parts';
import { BreadcrumbList } from '../../ui/breadcrumb-list/breadcrumb-list';
import { dialogAction } from '../../../slices/dialog-slice';
import iconCalendarOff from '../../../assets/images/common/icon_calendar_off.svg';
import iconCalendarOn from '../../../assets/images/common/icon_calendar_on.svg';
import crossOff from '../../../assets/images/common/cross_off.svg';
import crossOn from '../../../assets/images/common/cross_on.svg';
import { useAppSelector } from '../../../app/hooks';
import { DateFormatter } from '../../../utilities';
import { companyAction, CompanyParams, companyDefaultData } from '../../../slices/company-slice';
import { apiIspCompaniesAction } from '../../../slices/api-isp/api-isp-companies-slice';
import { apiIspCommonAction } from '../../../slices/api-isp/api-isp-common-slicer';
import { apiIsp } from '../../../managers/api-manager';
import { QueryParamFormatter } from '../../../utilities/query-param-formatter';
import * as lodash from 'lodash';
import { useDidMount } from '../../../hooks/life-cycle';
import { CheckAllValid, Validation } from '../../../managers/validation/validation';
import { ValidationFactory } from '../../../managers/validation/validation-factory';
import { ApiGetCompanyDetailResponse } from 'isp_cloud_api/dist/api/isp-web/companies/api-get-company-detail.response';
import { IspLogOperation } from '../../../models/logs/isp-log-operation';

/** 住所取得 */
const findPrefecture = (prefectureKey: number) => {
  const foundIndex = prefectureList.findIndex((v) => Number(v.key) === prefectureKey);
  return foundIndex > -1 ? (
    prefectureList[foundIndex].key
  ) : (
    '13'
  );
};
/** パンくずリスト */
const ispBreadcrumbList = [
  breadcrumbParts.isp.home,
  breadcrumbParts.isp.companyList,
  breadcrumbParts.isp.companyDetail,
  breadcrumbParts.isp.companyEdit,
];

type CompanyDataProps = {
  type: 'add' | 'edit',
  companyCode?: string,
} & PageComponentDefaultProps;

// バリデーション用意
const validations: { [key: string]: Validation } = {
  length30: ValidationFactory('length30'),
  length50: ValidationFactory('length50'),
  length256: ValidationFactory('length256'),
  lengthJust3: ValidationFactory('lengthJust3'),
  lengthNum5Info: ValidationFactory('lengthNum5'),
  lengthNum5Log: ValidationFactory('lengthNum5'),
  lengthNum5Basis: ValidationFactory('lengthNum5'),
  lengthNum5Eco: ValidationFactory('lengthNum5'),
  lengthNum5Air: ValidationFactory('lengthNum5'),
  lengthNum5Disaster: ValidationFactory('lengthNum5'),
  kana: ValidationFactory('kana'),
  postCode: ValidationFactory('postCode'),
  phoneNumber: ValidationFactory('phoneNumber'),
  requireStorage: ValidationFactory('requireEdit'),
  requireUseCloud: ValidationFactory('requireEdit'),
  requireSuspensionDate: ValidationFactory('requireEdit'),
  checkDate: ValidationFactory('default'),
  requireEmail: ValidationFactory('default'),
  requireEmailConfirm: ValidationFactory('default'),
  checkMailConfirm: ValidationFactory('default'),
  UnderCloudInfo: ValidationFactory('default'),
  UnderCloudLog: ValidationFactory('default'),
  UnderCloudCadect1: ValidationFactory('default'),
  UnderBasisEco: ValidationFactory('default'),
  UnderBasisAir: ValidationFactory('default'),
  UnderBasisDisaster: ValidationFactory('default'),
  storage: new Validation({
    test: (v) => Number(v) >= 5 && Number(v) <= 500,
    errorMessages: ['5~500までの数字で入力して下さい'],
  }),
  cloud: new Validation({
    test: (v) => Number(v) > 0 && v.length <= 5,
    errorMessages: ['1~99999までの数字で入力して下さい'],
  }),
};
const CompanyData = (props: CompanyDataProps) => {
  const { companyCode, apiManger, type } = props;
  const dispatch = useDispatch();
  const { industryList, occupationList } = useAppSelector((state) => ({
    ...state.commonData,
  }), lodash.isEqual);
  const { breadcrumbName } = useAppSelector((state) => state.company);

  const [companyData, setCompanyData] = useState<CompanyParams>(companyDefaultData);
  const [breadcrumbList, setBreadcrumbList] = useState(ispBreadcrumbList);

  // -------------------- 企業情報state --------------------
  const [code, setCode] = useState(companyData.company_code);
  const [name, setName] = useState(companyData.company_name);
  const [kana, setKana] = useState(companyData.company_name_kana);
  const [business, setBusiness] = useState('');
  const [occupation, setOccupation] = useState('');
  const [postalCode, setPostalCode] = useState(companyData.zip_code);
  const [addressTop, setAddressTop] = useState(companyData.pref_id);
  const [addressMiddle, setAddressMiddle] = useState(companyData.address);
  const [addressLast, setAddressLast] = useState(companyData.others);
  const [phoneNumber, setphoneNumber] = useState(companyData.tel);
  const [storage, setStorage] = useState(companyData.storage_size);
  const [useCloud, setUseCloud] = useState(companyData.member_use_count);
  const [useInformation, setUseInformation] = useState(companyData.info_use_count);
  const [useLogReference, setUseLogReference] = useState(companyData.log_use_count);
  const [useCadectBasis, setUseCadectBasis] = useState(companyData.cadect1_use_count);
  const [useCadectEco, setUseCadectEco] = useState(companyData.cadect2_use_count);
  const [useCadectAir, setUseCadectAir] = useState(companyData.cadect3_use_count);
  const [useCadectDisaster, setUseCadectDisaster] = useState(companyData.cadect4_use_count);
  const [suspensionDateFlag, setSuspensionDateFlag] = useState(Boolean(companyData.scheduled_to_stop_at));
  const [suspensionDate, setSuspensionDate] = useState(companyData.updated_at ? DateFormatter.date2str(new Date(companyData.scheduled_to_stop_at)) : '');
  // -------------------- オーナー情報state --------------------
  const [lastNameKanji, setLastNameKanji] = useState(companyData.family_name);
  const [firstNameKanji, setFirstNameKanji] = useState(companyData.name);
  const [lastNameKana, setLastNameKana] = useState(companyData.family_name_kana);
  const [firstNameKana, setFirstNameKana] = useState(companyData.name_kana);
  const [email, setEmail] = useState(companyData.email);
  const [emailConfirm, setEmailConfirm] = useState(companyData.email);
  const [industryName, setIndustryName] = useState('');
  const [occupationName, setOccupationName] = useState('');
  const [resolveFlag, setResolveFlag] = useState(false);
  // ユーザー情報取得
  const { userInfo } = useAppSelector((state) => state.loginIspUserInfo);

  // バリデーションのトータルチェック
  const [isDisabled, setIsDisabled] = useState(CheckAllValid(validations));
  const mailRef = useRef<HTMLInputElement>(null);
  const mailConfirmRef = useRef<HTMLInputElement>(null);
  const cloudRef = useRef<HTMLInputElement>(null);
  const infoRef = useRef<HTMLInputElement>(null);
  const logRef = useRef<HTMLInputElement>(null);
  const cadect1Ref = useRef<HTMLInputElement>(null);
  const cadect2Ref = useRef<HTMLInputElement>(null);
  const cadect3Ref = useRef<HTMLInputElement>(null);
  const cadect4Ref = useRef<HTMLInputElement>(null);
  const dateRef = useRef<HTMLInputElement>(null);
  const [validFlag, setValidFlag] = useState(false);
  const [cloudValidFlag, setCloudValidFlag] = useState(false);
  const [cadectValidFlag, setCadectValidFlag] = useState(false);
  const [dateValidFlag, setDateValidFlag] = useState(false);

  //　バリデーション用登録時の日付
  const now = useMemo(() => {
    return new Date();
  }, []);
  const year = now.getFullYear();
  const month = now.getMonth() + 1;
  const day = now.getDate();
  const todayValid = useMemo(() => {
    return String(year) + ZeroPadding(month, 2) + ZeroPadding(day, 2);
  }, [year, month, day]);

  // -------------------- イベント --------------------
  /** 企業コード変更 */
  const handleChangeCode = useCallback(
    (v: string) => {
      setCode(v.replace(/[^a-z,A-Z]*$/, '').toUpperCase());
    },
    [],
  );
  /** 企業名変更 */
  const handleChangeName = useCallback(
    (v) => {
      setName(v);
    },
    [],
  );
  /** 企業フリガナ変更 */
  const handleChangeKana = useCallback(
    (v) => {
      setKana(v);
    }, [],
  );
  /** 業種セレクト */
  const handleChangeBusiness = useCallback(
    (v) => {
      setBusiness(v);
    }, [],
  );
  /** 職種セレクト */
  const handleChangeOccupation = useCallback(
    (v) => {
      setOccupation(v);
    },
    [],
  );
  /** 郵便番号変更 */
  const handleChangePostalCode = useCallback(
    (v) => {
      setPostalCode(v.replace(/[^0-9\-]+$/, ''));
    }, [],
  );
  /** 都道府県セレクト */
  const handleChangeAddressTop = useCallback(
    (v) => {
      setAddressTop(v);
    }, [],
  );
  /** 市区町村変更 */
  const handleChangeAddressMiddle = useCallback(
    (v) => {
      setAddressMiddle(v);
    }, [],
  );
  /** 物件名など変更 */
  const handleChangeAddressLast = useCallback(
    (v) => {
      setAddressLast(v);
    }, [],
  );
  /** 電話番号変更 */
  const handleChangePhoneNumber = useCallback(
    (v) => {
      setphoneNumber(v.replace(/[^0-9\-]+$/, ''));
    }, [],
  );
  /** オーナー名変更 */
  const handleChangeLastNameKanji = useCallback(
    (v) => {
      setLastNameKanji(v);
    },
    [],
  );
  /** オーナー姓変更 */
  const handleChangeFirstNameKanji = useCallback(
    (v) => {
      setFirstNameKanji(v);
    },
    [],
  );
  /** オーナー名カナ変更 */
  const handleChangeLastNameKana = useCallback(
    (v) => {
      setLastNameKana(v);
    },
    [],
  );
  /** オーナー姓カナ変更 */
  const handleChangeFirstNameKana = useCallback(
    (v) => {
      setFirstNameKana(v);
    },
    [],
  );
  /** メールアドレス変更 */
  const handleChangeEmail = useCallback(
    (v) => {
      setEmail(v.replace(/[^a-zA-Z0-9!-/:-@¥[-`{-~]*$/, ''));
    },
    [],
  );
  /** アドレス確認変更 */
  const handleChangeCheckEmail = useCallback(
    (v) => {
      setEmailConfirm(v.replace(/[^a-zA-Z0-9!-/:-@¥[-`{-~]*$/, ''));
    },
    [],
  );
  /** ストレージ変更 */
  const handleChangeStorage = useCallback(
    (v) => {
      setStorage(v.replace(/[^0-9]/g, ''));
    },
    [],
  );
  /** クラウド利用者数変更 */
  const handleChangeUseCloud = useCallback(
    (v) => {
      setUseCloud(v.replace(/[^0-9]/g, ''));
      // ０の時は他の利用者も０にする
      if (v <= 0) {
        setUseInformation(0);
        setUseLogReference(0);
        setUseCadectBasis(0);
        setUseCadectAir(0);
        setUseCadectEco(0);
        setUseCadectDisaster(0);
      }
      setCloudValidFlag(!cloudValidFlag);
    },
    [useInformation,
      useLogReference,
      useCadectBasis,
      useCadectEco,
      useCadectAir,
      useCadectDisaster,
      cloudValidFlag],
  );
  /** インフォメーション利用者数変更 */
  const handleChangeUseInformation = useCallback(
    (v) => {
      setUseInformation(v.replace(/[^0-9]/g, ''));
    },
    [],
  );
  /** ログ利用者数変更 */
  const handleChangeUseLogReference = useCallback(
    (v) => {
      setUseLogReference(v.replace(/[^0-9]/g, ''));
    },
    [],
  );
  /** cadect基本利用者数変更 */
  const handleChangeUseCadectBasis = useCallback(
    (v) => {
      setUseCadectBasis(v.replace(/[^0-9]/g, ''));
      // ０の時、cadect利用者数を全て０にする
      if (v <= 0) {
        setUseCadectAir(0);
        setUseCadectEco(0);
        setUseCadectDisaster(0);
      }
      setCadectValidFlag(!cadectValidFlag);
    },
    [useCadectEco,
      useCadectAir,
      useCadectDisaster,
      cadectValidFlag],
  );
  /** cadect省エネ利用者数変更 */
  const handleChangeUseCadectEco = useCallback(
    (v) => {
      setUseCadectEco(v.replace(/[^0-9]/g, ''));
    },
    [],
  );
  /** cadect空調利用者数変更 */
  const handleChangeUseCadectAir = useCallback(
    (v) => {
      setUseCadectAir(v.replace(/[^0-9]/g, ''));
    },
    [],
  );
  /** cadect災害利用者数変更 */
  const handleChangeUseCadectDisaster = useCallback(
    (v) => {
      setUseCadectDisaster(v.replace(/[^0-9]/g, ''));
    },
    [],
  );
  /** 利用停止日セレクト */
  const handleChangeSuspensionDateFlag = useCallback(
    (v) => {
      if (!v) {
        // チェックボックスoff時に利用停止日をクリアする
        setSuspensionDate('');
      }
      setSuspensionDateFlag(v);
      setDateValidFlag(!dateValidFlag);
    },
    [dateValidFlag,
      suspensionDate],
  );
  /** 利用停止日変更 */
  const handleChangeSuspensionDate = useCallback(
    (v) => {
      setSuspensionDate(v);
      setDateValidFlag(!dateValidFlag);
    },
    [dateValidFlag],
  );
  /** 利用停日クリアボタン押下（バツボタン） */
  const handleClickClearDate = useCallback(
    () => {
      // 利用停止日をクリアする
      if (!suspensionDate) {
        dateRef.current?.focus();
        return;
      }
      setSuspensionDate('');
      dateRef.current?.focus();
      setDateValidFlag(!dateValidFlag);
    },
    [dateValidFlag, suspensionDate],
  );
  /** キャンセル押下 */
  const handleClickCancel = useCallback(
    () => {
      if (type === 'add') {
        IspLogOperation('companyAddCancel', () => {
          const query = QueryParamFormatter.queryParse();
          const _query = QueryParamFormatter.queryCompose(query);
          dispatch(push(RoutingPath.ispCompanyList + '?' + _query));
        });

      } else {
        IspLogOperation('companyEditCancel', () => {
          const path = RoutingPath.ispCompanyDetail
            .replace(':companyCode', companyCode!);
          dispatch(push(path));
        });

      }
    },
    [],
  );
  /** 登録押下 */
  const handleClickRegistration = () => {
    setIsDisabled(true);
    if (type === 'edit') {
      // 編集
      dispatch(apiIspCompaniesAction.update({
        companyCode: code,
        param: {
          company_code: code,
          company_name: name,
          company_name_kana: kana,
          industry_id: Number(business),
          occupation_id: Number(occupation),
          zip_code: postalCode,
          pref_id: addressTop,
          address: addressMiddle,
          others: addressLast,
          tel: phoneNumber,
          storage_size: storage,
          member_use_count: useCloud,
          info_use_count: useInformation,
          log_use_count: useLogReference,
          cadect1_use_count: useCadectBasis,
          cadect2_use_count: useCadectEco,
          cadect3_use_count: useCadectAir,
          cadect4_use_count: useCadectDisaster,
          scheduled_to_stop_at: suspensionDateFlag ? DateFormatter.date2str(new Date(suspensionDate), 'YYYYMMDD', '-') : '',
          pass: '',
        },
        callback: {
          success: () => {
            dispatch(dialogAction.pushMessage({
              title: '完了',
              message: ['更新しました'],
              buttons: [{
                label: 'OK',
                callback: () => {
                  dispatch(dialogAction.pop());
                  const path = RoutingPath.ispCompanyDetail
                    .replace(':companyCode', code);
                  dispatch(push(path));
                },
              }],
            }));
          },
          failed: () => setIsDisabled(false),
        },
      }));
    } else {
      // 登録
      dispatch(apiIspCompaniesAction.register({
        param: {
          company_code: code,
          company_name: name,
          company_name_kana: kana,
          industry_id: business,
          occupation_id: occupation,
          zip_code: postalCode,
          pref_id: addressTop,
          address: addressMiddle,
          others: addressLast,
          tel: phoneNumber,
          owner_family_name: lastNameKanji,
          owner_name: firstNameKanji,
          owner_family_name_kana: lastNameKana,
          owner_name_kana: firstNameKana,
          email: email,
          storage_size: storage,
          member_use_count: useCloud,
          info_use_count: useInformation,
          log_use_count: useLogReference,
          cadect1_use_count: useCadectBasis,
          cadect2_use_count: useCadectEco,
          cadect3_use_count: useCadectAir,
          cadect4_use_count: useCadectDisaster,
          scheduled_to_stop_at: suspensionDateFlag ? DateFormatter.date2str(new Date(suspensionDate), 'YYYYMMDD', '-') : '',
          pass: `#/isp/companies/${code}/members/add/`,
        } as any,
        callback: {
          success: () => {
            dispatch(dialogAction.pushSendMail({
              message: [
                '追加した企業オーナーの',
                'メールアドレスへ',
                '新規登録画面の案内が届きます',
              ],
              callback: () => {
                const query = QueryParamFormatter.queryParse();
                const _query = QueryParamFormatter.queryCompose(query);
                dispatch(push(RoutingPath.ispCompanyList + '?' + _query));
              },
            }));
          },
          failed: () => setIsDisabled(false),
        },
      }));
    }
  };

  /** 相互関係のあるバリデーションを自動でチェック */
    // メール
  const checkValidMailInput = useCallback(() => {
      if (!mailRef.current ||
        !mailConfirmRef.current ||
        mailConfirmRef.current.value.length <= 0) {
        return;
      }
      mailConfirmRef.current.focus();
      mailConfirmRef.current.blur();
      mailRef.current.focus();
    }, [email]);
  // クラウド利用者数
  const checkValidUseCloud = useCallback(() => {
    if (!cloudRef.current ||
      !infoRef.current ||
      !logRef.current ||
      !cadect1Ref.current ||
      !cadect2Ref.current ||
      !cadect3Ref.current ||
      !cadect4Ref.current) {
      return;
    }
    infoRef.current.focus();
    infoRef.current.blur();
    logRef.current.focus();
    logRef.current.blur();
    cadect1Ref.current.focus();
    cadect1Ref.current.blur();
    cadect2Ref.current.focus();
    cadect2Ref.current.blur();
    cadect3Ref.current.focus();
    cadect3Ref.current.blur();
    cadect4Ref.current.focus();
    cadect4Ref.current.blur();
    cloudRef.current.focus();
  }, [cloudValidFlag]);
  // cadect利用者数
  const checkValidUseCadect = useCallback(() => {
    if (!cadect1Ref.current ||
      !cadect2Ref.current ||
      !cadect3Ref.current ||
      !cadect4Ref.current) {
      return;
    }
    cadect2Ref.current.focus();
    cadect2Ref.current.blur();
    cadect3Ref.current.focus();
    cadect3Ref.current.blur();
    cadect4Ref.current.focus();
    cadect4Ref.current.blur();
    cadect1Ref.current.focus();
  }, [cadectValidFlag]);
  // 利用停止日
  const checkValidDateInput = useCallback(() => {
    if (!dateRef.current) {
      return;
    }
    dateRef.current.focus();
    dateRef.current.blur();
    dateRef.current.focus();
  }, [suspensionDate, todayValid]);
  // -------------------- effect --------------------
  /** 変更を検知してバリデーションをチェックする */
  // メールチェック
  useEffect(() => {
    if (validFlag && type === 'add') {
      checkValidMailInput();
    }
  }, [email]);
  // クラウドチェック
  useEffect(() => {
    if (validFlag) {
      checkValidUseCloud();
    }
  }, [cloudValidFlag]);
  // cadectチェック
  useEffect(() => {
    if (validFlag) {
      checkValidUseCadect();
    }
  }, [cadectValidFlag]);
  // 利用日チェック
  useEffect(() => {
    if (validFlag) {
      checkValidDateInput();
    }
  }, [dateValidFlag]);
  // トータルチェック
  useEffect(() => {
    setIsDisabled(CheckAllValid(validations));
  }, [
    code,
    name,
    kana,
    business,
    occupation,
    postalCode,
    addressTop,
    addressMiddle,
    addressLast,
    phoneNumber,
    lastNameKanji,
    firstNameKanji,
    lastNameKana,
    firstNameKana,
    email,
    emailConfirm,
    storage,
    useCloud,
    useInformation,
    useCadectBasis,
    useCadectEco,
    useCadectAir,
    useCadectDisaster,
    suspensionDateFlag,
    suspensionDate,
  ]);
  /** 新規登録時はデフォルトを空にする */
  useEffect(() => {
    if (!userInfo.user_id) {
      return;
    }
    if (type === 'add') {
      dispatch(companyAction.clearCompanyDetail());
    }
  }, []);
  /** 編集時の初期値設定 */
  useEffect(() => {
    if (code !== companyData.company_code) {
      setCode(companyData.company_code);
    }
    if (name !== companyData.company_name) {
      setName(companyData.company_name);
    }
    if (kana !== companyData.company_name_kana) {
      setKana(companyData.company_name_kana);
    }
    if (business !== String(companyData.industry_id) && type === 'edit') {
      setBusiness(String(companyData.industry_id));
    }
    if (occupation !== String(companyData.occupation_id) && type === 'edit') {
      setOccupation(String(companyData.occupation_id));
    }
    if (postalCode !== companyData.zip_code) {
      setPostalCode(companyData.zip_code);
    }
    if (addressTop !== companyData.pref_id) {
      setAddressTop(companyData.pref_id);
    }
    if (addressMiddle !== companyData.address) {
      setAddressMiddle(companyData.address);
    }
    if (addressLast !== companyData.others) {
      setAddressLast(companyData.others ?? '');
    }
    if (phoneNumber !== companyData.tel) {
      setphoneNumber(companyData.tel);
    }
    if (lastNameKanji !== companyData.family_name) {
      setLastNameKanji(companyData.family_name ?? '');
    }
    if (firstNameKanji !== companyData.name) {
      setFirstNameKanji(companyData.name ?? '');
    }
    if (lastNameKana !== companyData.family_name_kana) {
      setLastNameKana(companyData.family_name_kana ?? '');
    }
    if (firstNameKana !== companyData.name_kana) {
      setFirstNameKana(companyData.name_kana ?? '');
    }
    if (email !== companyData.email) {
      setEmail(companyData.email ?? '');
    }
    if (emailConfirm !== companyData.email) {
      setEmailConfirm(companyData.email ?? '');
    }
    if (storage !== companyData.storage_size) {
      setStorage(companyData.storage_size);
    }
    if (useCloud !== companyData.member_use_count) {
      setUseCloud(companyData.member_use_count);
    }
    if (useInformation !== companyData.info_use_count) {
      setUseInformation(companyData.info_use_count);
    }
    if (useLogReference !== companyData.log_use_count) {
      setUseLogReference(companyData.log_use_count);
    }
    if (useCadectBasis !== companyData.cadect1_use_count) {
      setUseCadectBasis(companyData.cadect1_use_count);
    }
    if (useCadectEco !== companyData.cadect2_use_count) {
      setUseCadectEco(companyData.cadect2_use_count);
    }
    if (useCadectAir !== companyData.cadect3_use_count) {
      setUseCadectAir(companyData.cadect3_use_count);
    }
    if (useCadectDisaster !== companyData.cadect4_use_count) {
      setUseCadectDisaster(companyData.cadect4_use_count);
    }
    if (suspensionDateFlag !== Boolean(companyData.scheduled_to_stop_at)) {
      setSuspensionDateFlag(Boolean(companyData.scheduled_to_stop_at));
    }
    if (suspensionDate !== companyData.scheduled_to_stop_at ? DateFormatter.date2str(new Date(companyData.scheduled_to_stop_at)) : '') {
      setSuspensionDate(companyData.scheduled_to_stop_at ? DateFormatter.date2str(new Date(companyData.scheduled_to_stop_at)) : '');
    }
  }, [companyData]);
  /** 登録と編集の表示分岐 */
  useEffect(() => {
    let unmounted = false;
    if (type === 'add') {
      // add
      setBreadcrumbList([
        breadcrumbParts.isp.home,
        breadcrumbParts.isp.companyList,
        breadcrumbParts.isp.companyRegister,
      ]);
    } else {
      // edit
      if (breadcrumbName) {
        const path = RoutingPath.ispCompanyDetail
          .replace(':companyCode', companyCode!);
        breadcrumbParts.isp.companyDetail.route = path;
        breadcrumbParts.isp.companyDetail.label = breadcrumbName.length > 20 ?
          breadcrumbName.slice(0, 20) + '...' :
          breadcrumbName;
        setBreadcrumbList([
          breadcrumbParts.isp.home,
          breadcrumbParts.isp.companyList,
          breadcrumbParts.isp.companyDetail,
          breadcrumbParts.isp.companyEdit,
        ]);
        setResolveFlag(true);
      }
      (apiIsp.companies(companyCode).get() as Promise<ApiGetCompanyDetailResponse>)
        .then((v: ApiGetCompanyDetailResponse) => {
          if (!unmounted) {
            const data: CompanyParams = v.body.data;
            setCompanyData(v.body.data);
            if (!breadcrumbName) {
              const path = RoutingPath.ispCompanyDetail
                .replace(':companyCode', companyCode!);
              dispatch(companyAction.setBreadcrumbName({
                breadcrumbName: data.company_name,
              }));
              breadcrumbParts.isp.companyDetail.route = path;
              breadcrumbParts.isp.companyDetail.label = data.company_name.length > 20 ?
                data.company_name.slice(0, 20) + '...' :
                data.company_name;
              setBreadcrumbList([
                breadcrumbParts.isp.home,
                breadcrumbParts.isp.companyList,
                breadcrumbParts.isp.companyDetail,
                breadcrumbParts.isp.companyEdit,
              ]);
              setResolveFlag(true);
            }
          }
        });
    }
    return () => {
      unmounted = true;
    };
  }, [type]);
  /** 職種と業種を設定 */
  useEffect(() => {
    if (!userInfo.user_id) {
      return;
    }
    const industryFoundIndex = industryList.findIndex((v) => v.id === Number(business));
    if (industryFoundIndex > -1) {
      setIndustryName(industryList[industryFoundIndex].name);
    }
    const occupationFoundIndex = occupationList.findIndex((v) => v.id === Number(occupation));
    if (occupationFoundIndex > -1) {
      setOccupationName(occupationList[occupationFoundIndex].name);
    }
  }, [business, occupation]);
  useDidMount(() => {
    if (!userInfo.user_id) {
      return;
    }
    dispatch(apiIspCommonAction.getIndustryList());
    dispatch(apiIspCommonAction.getOccupationList());
    setValidFlag(true);
  });
  const industry = useMemo(() => {
    return industryList.map((v) => ({
      key: String(v.id),
      label: v.name,
    }));
  }, [industryList]);
  const occupations = useMemo(() => {
    return [...occupationList].map((v) => ({
      key: String(v.id),
      label: v.name,
    }));
  }, [occupationList]);
  /** バリデーション更新 */
  useEffect(() => {
    if (type === 'edit') {
      validations['requireName'] = ValidationFactory('requireEdit');
      validations['requireKana'] = ValidationFactory('requireEdit');
      validations['requirePostalCode'] = ValidationFactory('requireEdit');
      validations['requireAddressMiddle'] = ValidationFactory('requireEdit');
      validations['requirePhoneNumber'] = ValidationFactory('requireEdit');
      validations['requireBusiness'] = ValidationFactory('requireEdit');
      validations['requirePosition'] = ValidationFactory('requireEdit');
      validations['checkDate'] = new Validation({
        test: (v: string) =>
          v === '' || (Number(v.replace(/-/g, '')) >= Number(todayValid)),
        errorMessages: ['過去の日付は設定できません'],
        onInvalid: () => setIsDisabled(CheckAllValid(validations)),
      });
      delete validations.requireCode;
      delete validations.requireLastNameKanji;
      delete validations.requireFirstNameKanji;
      delete validations.requireLastNameKana;
      delete validations.requireFirstNameKana;
      delete validations.requireEmail;
      delete validations.requireEmailConfirm;
      delete validations.checkMailConfirm;
    } else {
      validations['requireCode'] = ValidationFactory('require');
      validations['requireName'] = ValidationFactory('require');
      validations['requireKana'] = ValidationFactory('require');
      validations['requirePostalCode'] = ValidationFactory('require');
      validations['requireAddressMiddle'] = ValidationFactory('require');
      validations['requirePhoneNumber'] = ValidationFactory('require');
      validations['requireLastNameKanji'] = ValidationFactory('require');
      validations['requireFirstNameKanji'] = ValidationFactory('require');
      validations['requireLastNameKana'] = ValidationFactory('require');
      validations['requireFirstNameKana'] = ValidationFactory('require');
      validations['requireEmail'] = ValidationFactory('require');
      validations['requireEmailConfirm'] = ValidationFactory('require');
      validations['checkMailConfirm'] = new Validation({
        test: (v: string) => v === mailRef.current?.value,
        errorMessages: ['メールアドレスが一致しません'],
      });
      validations['requireBusiness'] = ValidationFactory('require');
      validations['requirePosition'] = ValidationFactory('require');
    }
    validations['UnderCloudInfo'] = new Validation({
      test: (v: string) => Number(v) <= Number(cloudRef.current?.value),
      errorMessages: ['B-LOOP利用者数以下で設定してください'],
    });
    validations['UnderCloudLog'] = new Validation({
      test: (v: string) => Number(v) <= Number(cloudRef.current?.value),
      errorMessages: ['B-LOOP利用者数以下で設定してください'],
    });
    validations['UnderCloudCadect1'] = new Validation({
      test: (v: string) => Number(v) <= Number(cloudRef.current?.value),
      errorMessages: ['B-LOOP利用者数以下で設定してください'],
    });
    validations['UnderBasisEco'] = new Validation({
      test: (v: string) => Number(v) <= Number(cadect1Ref.current?.value),
      errorMessages: ['CADECT-基本利用者数以下で設定してください'],
    });
    validations['UnderBasisAir'] = new Validation({
      test: (v: string) => Number(v) <= Number(cadect1Ref.current?.value),
      errorMessages: ['CADECT-基本利用者数以下で設定してください'],
    });
    validations['UnderBasisDisaster'] = new Validation({
      test: (v: string) => Number(v) <= Number(cadect1Ref.current?.value),
      errorMessages: ['CADECT-基本利用者数以下で設定してください'],
    });
    setIsDisabled(CheckAllValid(validations));
  }, [validFlag]);

  useEffect(() => {
    if (suspensionDateFlag) {
      // チェックボックスon
      // 利用停止日バリデーション追加
      validations['requireSuspensionDate'] = ValidationFactory('suspensionDate');
      if (type === 'add') {
        // 新規登録の場合
        validations['checkDate'] = new Validation({
          test: (v: string) =>
            v === '' || (Number(v.replace(/-/g, '')) > Number(todayValid)),
          errorMessages: ['明日以降を設定して下さい'],
          onInvalid: () => setIsDisabled(CheckAllValid(validations)),
        });
      } else {
        // 編集の場合
        validations['checkDate'] = new Validation({
          test: (v: string) =>
            v === '' || (Number(v.replace(/-/g, '')) >= Number(todayValid)),
          errorMessages: ['過去の日付は設定できません'],
          onInvalid: () => setIsDisabled(CheckAllValid(validations)),
        });
      }
    } else {
      // チェックボックスoff
      // 利用停止日バリデーション解除
      validations['requireSuspensionDate'] = ValidationFactory('default');
      validations['checkDate'] = ValidationFactory('default');
    }
    setIsDisabled(CheckAllValid(validations));
  }, [suspensionDateFlag]);

  return (
    <div
      id="App"
      className="company edit"
    >
      <SideBar currentPage={type === 'add' ? 'company-add' : 'company-list'} />
      <div className="main_cnt">
        <Header />
        <div className="inner">
          {type === 'add' || breadcrumbName || resolveFlag ?
            <BreadcrumbList breadcrumbList={breadcrumbList} /> :
            <></>}
          <section>
            <header>
              <h2>{type === 'edit' ? '企業登録情報変更' : '新規企業登録'}</h2>
            </header>
            <div className="edit_wrap">
              <h3>■企業情報</h3>
              <div className="edit_box">
                <div className="item_wrap">
                  <div className="item_head">
                    企業コード
                    {type === 'edit' || <span className="required">必須</span>}
                  </div>
                  <div className="item_cnt">
                    {type === 'edit' ?
                      //変更
                      <label className="text_box">{code}</label> :
                      //新規
                      <>
                        <Input
                          value={code}
                          boxSize="x_small"
                          maxLength={3}
                          onChange={(e) => handleChangeCode(e.target.value)}
                          validations={[
                            validations.requireCode,
                            validations.lengthJust3,
                          ]}
                        />
                        <span className="comment">
                          半角英字 3文字でご入力ください
                        </span>
                      </>
                    }
                  </div>
                </div>
                <hr />
                <div className="item_wrap">
                  <div className="item_head">
                    企業名
                    <span className="required">必須</span>
                  </div>
                  <div className="item_cnt">
                    <Input
                      value={name}
                      boxSize="large"
                      onChange={(e) => handleChangeName(e.target.value)}
                      validations={[
                        validations.requireName,
                        validations.length50,
                      ]}
                    />
                  </div>
                </div>
                <div className="item_wrap">
                  <div className="item_head">
                    企業名（カナ）
                    <span className="required">必須</span>
                  </div>
                  <div className="item_cnt">
                    <Input
                      value={kana}
                      boxSize="large"
                      onChange={(e) => handleChangeKana(e.target.value)}
                      validations={[
                        validations.requireKana,
                        validations.length50,
                        validations.kana,
                      ]}
                    />
                  </div>
                </div>
                <hr />
                <div className="item_wrap">
                  <div className="item_head">
                    業種
                    <span className="required">必須</span>
                  </div>
                  <div className="item_cnt">
                    <Select
                      value={String(business)}
                      list={industry}
                      onChange={handleChangeBusiness}
                      placeholder=" "
                      validations={[
                        validations.requireBusiness,
                      ]}
                    />
                  </div>
                </div>
                <hr />
                <div className="item_wrap">
                  <div className="item_head">
                    郵便番号
                    <span className="required">必須</span>
                  </div>
                  <div className="item_cnt flex">
                    <label>〒</label>
                    <Input
                      className="postal_coad"
                      value={postalCode}
                      placeholder="例）100-0000"
                      onChange={(e) => handleChangePostalCode(e.target.value)}
                      validations={[
                        validations.requirePostalCode,
                        validations.postCode,
                      ]}
                    />
                    <span className="comment">
                      半角数字・ハイフンあり
                    </span>
                  </div>
                </div>
                <div className="item_wrap">
                  <div className="item_head">
                    都道府県
                    <span className="required">必須</span>
                  </div>
                  <div className="item_cnt">
                    <Select
                      value={findPrefecture(Number(addressTop))}
                      list={prefectureList}
                      onChange={handleChangeAddressTop}
                    />
                  </div>
                </div>
                <div className="item_wrap">
                  <div className="item_head">
                    企業住所（市区町村・番地）
                    <span className="required">必須</span>
                  </div>
                  <div className="item_cnt">
                    <Input
                      value={addressMiddle}
                      boxSize="large"
                      onChange={(e) => handleChangeAddressMiddle(e.target.value)}
                      validations={[
                        validations.requireAddressMiddle,
                        validations.length50,
                      ]}
                    />
                  </div>
                </div>
                <div className="item_wrap">
                  <div className="item_head">
                    企業住所（ビル名等）
                  </div>
                  <div className="item_cnt">
                    <Input
                      value={addressLast}
                      boxSize="large"
                      onChange={(e) => handleChangeAddressLast(e.target.value)}
                      validations={[
                        validations.length50,
                      ]}
                    />
                  </div>
                </div>
                <hr />
                <div className="item_wrap">
                  <div className="item_head">
                    電話番号
                    <span className="required">必須</span>
                  </div>
                  <div className="item_cnt">
                    <Input
                      value={phoneNumber}
                      placeholder="例）01-2345-6789"
                      onChange={(e) => handleChangePhoneNumber(e.target.value)}
                      validations={[
                        validations.requirePhoneNumber,
                        validations.phoneNumber,
                      ]}
                    />
                    <span className="comment">
                      半角数字・ハイフンあり
                    </span>
                  </div>
                </div>
              </div>

              <h3>■オーナー情報</h3>
              {type === 'edit' ?
                <>
                  <div className="edit_box">
                    <div className="item_wrap">
                      <div className="item_head">
                        企業担当者名
                      </div>
                      <div className="item_cnt flex">
                        <div
                          style={{ marginRight: '30px' }}
                        >
                          <label className="text_box">{`${lastNameKanji} ${firstNameKanji}`}</label>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className="item_wrap">
                      <div className="item_head">
                        企業担当者（カナ）
                      </div>
                      <div className="item_cnt flex">
                        <div
                          style={{ marginRight: '30px' }}
                        >
                          <label className="text_box">{`${lastNameKana} ${firstNameKana}`}</label>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className="item_wrap">
                      <div className="item_head">
                        企業担当者（メールアドレス）
                      </div>
                      <div className="item_cnt">
                        <label className="text_box">{email}</label>
                      </div>
                    </div>
                    <hr />
                    <div className="item_wrap">
                      <div className="item_head">
                        職種
                      </div>
                      <div className="item_cnt">
                        <label className="text_box">{occupationName}</label>
                      </div>
                    </div>

                  </div>
                </>
                : <>
                  <div className="edit_box">
                    <div className="item_wrap">
                      <div className="item_head">
                        企業担当者名
                        <span className="required">必須</span>
                      </div>
                      <div className="item_cnt flex">
                        <div
                          style={{ marginRight: '30px' }}
                        >
                          <label className="fix_size">姓</label>
                          <Input
                            value={lastNameKanji}
                            onChange={(e) => handleChangeLastNameKanji(e.target.value)}
                            validations={[
                              validations.requireLastNameKanji,
                              validations.length30,
                            ]}
                          />
                        </div>
                        <div>
                          <label className="fix_size">名</label>
                          <Input
                            value={firstNameKanji}
                            onChange={(e) => handleChangeFirstNameKanji(e.target.value)}
                            validations={[
                              validations.requireFirstNameKanji,
                              validations.length30,
                            ]}
                          />
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className="item_wrap">
                      <div className="item_head">
                        企業担当者（カナ）
                        <span className="required">必須</span>
                      </div>
                      <div className="item_cnt flex">
                        <div
                          style={{ marginRight: '30px' }}
                        >
                          <label className="fix_size">セイ</label>
                          <Input
                            value={lastNameKana}
                            onChange={(e) => handleChangeLastNameKana(e.target.value)}
                            validations={[
                              validations.requireLastNameKana,
                              validations.length30,
                              validations.kana,
                            ]}
                          />
                        </div>
                        <div>
                          <label className="fix_size">メイ</label>
                          <Input
                            value={firstNameKana}
                            onChange={(e) => handleChangeFirstNameKana(e.target.value)}
                            validations={[
                              validations.requireFirstNameKana,
                              validations.length30,
                              validations.kana,
                            ]}
                          />
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className="item_wrap">
                      <div className="item_head">
                        企業担当者（メールアドレス）
                        <span className="required">必須</span>
                      </div>
                      <div className="item_cnt">
                        <Input
                          value={email}
                          boxSize="medium"
                          onChange={(e) => handleChangeEmail(e.target.value)}
                          validations={[
                            validations.requireEmail,
                            validations.length256,
                          ]}
                          ref={mailRef}
                        />
                      </div>
                    </div>
                    <div className="item_wrap">
                      <div className="item_head">
                        企業担当者（メールアドレス再入力）
                        <span className="required">必須</span>
                      </div>
                      <div className="item_cnt">
                        <Input
                          value={emailConfirm}
                          boxSize="medium"
                          onChange={(e) => handleChangeCheckEmail(e.target.value)}
                          validations={[
                            validations.requireEmailConfirm,
                            validations.length256,
                            validations.checkMailConfirm,
                          ]}
                          ref={mailConfirmRef}
                        />
                      </div>
                    </div>
                    <hr />
                    <div className="item_wrap">
                      <div className="item_head">
                        職種
                        <span className="required">必須</span>
                      </div>
                      <div className="item_cnt">
                        <Select
                          value={String(occupation)}
                          list={occupations}
                          onChange={handleChangeOccupation}
                          placeholder=" "
                          validations={[
                            validations.requirePosition,
                          ]}
                        />
                      </div>
                    </div>

                  </div>
                </>
              }
              <h3>■利用者情報</h3>
              <div className="edit_box number_box">
                <div className="item_wrap">
                  <div className="item_head">
                    ストレージ
                    <span className="required">必須</span>
                  </div>
                  <div className="item_cnt">
                    <Input
                      type={'number'}
                      value={storage}
                      className="align_right"
                      boxSize="auto"
                      maxLength={3}
                      onChange={(e) => handleChangeStorage(e.target.value)}
                      validations={[
                        validations.requireStorage,
                        validations.storage,
                      ]}
                    />
                    <label className="unit">GB</label>
                    <span className="comment">
                      半角数字 5GB～500GBでご入力ください
                    </span>
                  </div>
                </div>
              </div>
              <div className="edit_box number_box">
                <div className="item_wrap fix">
                  <div className="item_head">
                    B-LOOP利用者数
                    <span className="required">必須</span>
                  </div>
                  <div className="item_cnt">
                    <Input
                      type={'number'}
                      value={useCloud}
                      className="align_right"
                      onChange={(e) => handleChangeUseCloud(e.target.value)}
                      validations={[
                        validations.requireUseCloud,
                        validations.cloud,
                      ]}
                      ref={cloudRef}
                    /><label className="unit">人</label>
                  </div>
                </div>
                <div className="item_wrap">
                  <div className="item_head">
                    インフォメーション利用者数
                  </div>
                  <div className={`item_cnt ${Number(useCloud) > 0 ? '' : 'disabled'}`}>
                    <Input
                      type={'number'}
                      value={useInformation}
                      className="align_right"
                      onChange={(e) => handleChangeUseInformation(e.target.value)}
                      validations={[
                        validations.lengthNum5Info,
                        validations.UnderCloudInfo,
                      ]}
                      ref={infoRef}
                    /><label className="unit">人</label>
                  </div>
                </div>
                <div className="item_wrap">
                  <div className="item_head">
                    ログ参照利用者数
                  </div>
                  <div className={`item_cnt ${Number(useCloud) > 0 ? '' : 'disabled'}`}>
                    <Input
                      type={'number'}
                      value={useLogReference}
                      className="align_right"
                      onChange={(e) => handleChangeUseLogReference(e.target.value)}
                      validations={[
                        validations.lengthNum5Log,
                        validations.UnderCloudLog,
                      ]}
                      ref={logRef}
                    /><label className="unit">人</label>
                  </div>
                </div>
                <div className="edit_box number_box">
                  <div className="item_wrap fix">
                    <div className="item_head">
                      CADECT-基本利用者数
                    </div>
                    <div className={`item_cnt ${Number(useCloud) > 0 ? '' : 'disabled'}`}>
                      <Input
                        type={'number'}
                        value={useCadectBasis}
                        className="align_right"
                        onChange={(e) => handleChangeUseCadectBasis(e.target.value)}
                        validations={[
                          validations.lengthNum5Basis,
                          validations.UnderCloudCadect1,
                        ]}
                        ref={cadect1Ref}
                      /><label className="unit">人</label>
                    </div>
                  </div>
                  <div className="item_wrap">
                    <div className="item_head">
                      CADECT-省エネ利用者数
                    </div>
                    <div className={`item_cnt ${Number(useCadectBasis) > 0 ? '' : 'disabled'}`}>
                      <Input
                        type={'number'}
                        value={useCadectEco}
                        className="align_right"
                        onChange={(e) => handleChangeUseCadectEco(e.target.value)}
                        validations={[
                          validations.lengthNum5Eco,
                          validations.UnderBasisEco,
                        ]}
                        ref={cadect2Ref}
                      /><label className="unit">人</label>
                    </div>
                  </div>
                  <div className="item_wrap">
                    <div className="item_head">
                      CADECT-空調利用者数
                    </div>
                    <div className={`item_cnt ${Number(useCadectBasis) > 0 ? '' : 'disabled'}`}>
                      <Input
                        type={'number'}
                        value={useCadectAir}
                        className="align_right"
                        onChange={(e) => handleChangeUseCadectAir(e.target.value)}
                        validations={[
                          validations.lengthNum5Air,
                          validations.UnderBasisAir,
                        ]}
                        ref={cadect3Ref}
                      /><label className="unit">人</label>
                    </div>
                  </div>
                  <div className="item_wrap">
                    <div className="item_head">
                      CADECT-防災利用者数
                    </div>
                    <div className={`item_cnt ${Number(useCadectBasis) > 0 ? '' : 'disabled'}`}>
                      <Input
                        type={'number'}
                        value={useCadectDisaster}
                        className="align_right"
                        onChange={(e) => handleChangeUseCadectDisaster(e.target.value)}
                        validations={[
                          validations.lengthNum5Disaster,
                          validations.UnderBasisDisaster,
                        ]}
                        ref={cadect4Ref}
                      /><label className="unit">人</label>
                    </div>
                  </div>
                </div>
              </div>
              <div className={`align_center date_box`} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div className="checkbox">
                  <Input
                    id="suspensionDateFlag"
                    type="checkbox"
                    //type="suspensionDateFlag"
                    checked={suspensionDateFlag}
                    onChange={() => handleChangeSuspensionDateFlag(!suspensionDateFlag)}
                  />
                  <label htmlFor="suspensionDateFlag">
                    利用停止日：
                  </label>
                </div>
                <Input
                  type="date"
                  readOnly={!suspensionDateFlag}
                  value={DateFormatter.date2str(new Date(suspensionDate), 'YYYYMMDD', '-')}
                  onChange={(e) => handleChangeSuspensionDate(e.target.value)}
                  validations={suspensionDateFlag ? [
                    validations.requireSuspensionDate,
                    validations.checkDate,
                  ] : []}
                  ref={dateRef}
                  disabled={!suspensionDateFlag}
                  onClickClearDate={handleClickClearDate}
                  style={{ backgroundColor: 'transparent' }}
                />
              </div>
              <div className="btn_box align_center">
                <Button
                  size="large"
                  color="tertiary"
                  label="キャンセル"
                  onClick={handleClickCancel}
                />
                {type === 'edit' ?
                  <Button
                    size="large"
                    label="変更"
                    onClick={handleClickRegistration}
                    // className={isDisabled ? 'disabled' : ''}
                    disabled={isDisabled}
                  />
                  :
                  <Button
                    size="large"
                    label="企業登録"
                    onClick={handleClickRegistration}
                    // className={isDisabled ? 'disabled' : ''}
                    disabled={isDisabled}
                  />
                }
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export const CompanyAdd = (props: PageComponentDefaultProps) => <CompanyData {...props} type="add" />;
export const CompanyEdit = (props: { companyCode: string; } & PageComponentDefaultProps) =>
  <CompanyData {...props} type="edit" />;
