import React, { useEffect, useState } from 'react';
import { Header } from '../../ui/header/header';
import { SideBar } from '../../ui/sidebar/sidebar';
import { TotalNumTip } from './total-num-tip';
import { UseStorageTip } from './use-storage-tip';
import { PageComponentDefaultProps } from '../../../models/page-component-default-props';
import { useDispatch } from 'react-redux';
import '../../../tip.scss';
import { apiIsp } from '../../../managers/api-manager';
import { PortalOrgData, PortalData } from '../../../models/portal-data';
import { useAppSelector } from '../../../app/hooks'

export const Portal = (props: PageComponentDefaultProps) => {
  const { apiManger } = props;
  const dispatch = useDispatch();
  const [storage, setStorage] = useState<PortalOrgData[]>([]);

  const [portal, setPortal] = useState<PortalData[]>([{
    totalNums: [{
      title: '全体企業登録数',
      num: 0,
      unit: '社'
    },
    {
      title: '全体ユーザー登録数',
      num: 0,
      unit: '人'
    },
    {
      title: '全体物件数',
      num: 0,
      unit: '件'
    },],
    organizations: storage
  }]);
  const { userInfo } = useAppSelector((state) => state.loginIspUserInfo);

  useEffect(() => {
    let unmounted = false;
    if (!userInfo.user_id) {
      return
    }
    apiIsp.portals().get()
      .then((v) => {
        if (!unmounted) {
          // const dummy = [
          //   {
          //     allocated_size: 100,
          //     company_name: 'test',
          //     used_size: 20
          //   },
          //   {
          //     allocated_size: 200,
          //     company_name: 'test2',
          //     used_size: 129
          //   },
          //   {
          //     allocated_size: 11,
          //     company_name: 'test3',
          //     used_size: 2
          //   },
          //   {
          //     allocated_size: 100,
          //     company_name: 'test4',
          //     used_size: 90
          //   },
          //   {
          //     allocated_size: 1000,
          //     company_name: 'test5',
          //     used_size: 1
          //   },
          // ];
          if (v.body.data.storage) {
            v.body.data.storage.sort((a, b) => {
              return (b.used_size / b.allocated_size) - (a.used_size / a.allocated_size);
            });
          }
          const portalData = [{
            totalNums: [{
              title: '全体企業登録数',
              num: v.body.data.company_count,
              unit: '社'
            },
              {
                title: '全体ユーザー登録数',
                num: v.body.data.user_count,
                unit: '人'
              },
              {
                title: '全体物件数',
                num: v.body.data.article_count,
                unit: '件'
              },],
            organizations: v.body.data.storage
            // organizations: dummy
          }];
          setPortal(portalData as any);
        }
      });
    return () => {unmounted = true}
  },[]);

  return (
  <div id="App" className="portal">
      <SideBar currentPage="portal" />
      <div className="main_cnt">
        <Header />
        <div className="inner">
          <h2>B-LOOP管理ポータル</h2>
          {portal.map((v, i) => (
            <div key={`portal-data_${i}`}>
              <div className="tip_box">
                {v.totalNums.map((num, j) => (
                  <TotalNumTip
                    key={`total-num-tip_${j}`}
                    title={num.title} num={num.num}
                    unit={num.unit}
                    icon={j}
                  />
                ))}
              </div>
              <div className="tip_box">
                {v.organizations.map((org, k) => (
                  <UseStorageTip
                    key={`use-storage-tip_${k}`}
                    organization={org.company_name}
                    limitGb={org.allocated_size}
                    usedGb={org.used_size}
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
