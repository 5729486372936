import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ActionType } from '../models/action-type';

export type EnvState = {
  type: string,
  mode: string,
  ispUrl: string,
  companyUrl: string,
  manuelUrl: string,
  startGuideUrl: string,
  adminGuideUrl: string,
  totalGuideUrl: string,
};

const initialState: EnvState = {
  type: '',
  mode: '',
  ispUrl: '',
  companyUrl: '',
  manuelUrl: '',
  startGuideUrl: '',
  adminGuideUrl: '',
  totalGuideUrl: '',
};

export const serverEnvSlice = createSlice({
  name: 'server-env',
  initialState,
  reducers: {
    set: (
      state,
      action: PayloadAction<EnvState>
    ) => ({
      ...state,
      type: action.payload.type,
      mode: action.payload.mode,
      ispUrl: action.payload.ispUrl,
      companyUrl: action.payload.companyUrl,
      manuelUrl: action.payload.manuelUrl,
      startGuideUrl: action.payload.startGuideUrl,
      adminGuideUrl: action.payload.adminGuideUrl,
      totalGuideUrl: action.payload.totalGuideUrl,
    }),
  },
});

export const serverEnvAction = serverEnvSlice.actions;
export const serverEnvReducer = serverEnvSlice.reducer;
