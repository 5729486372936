import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { breadcrumbParts } from '../../../models/breadcrumb-parts';
import { CompanyLogOperation } from '../../../models/logs/company-log-operation';
import { PageComponentDefaultProps } from '../../../models/page-component-default-props';
import { Table } from '../../table/table';
import { TableElement } from '../../table/table-element';
import { BreadcrumbList } from '../../ui/breadcrumb-list/breadcrumb-list';
import { Button } from '../../ui/button/button';
import { Header } from '../../ui/header/header';
import { SideBar } from '../../ui/sidebar/sidebar';
import './information-add.scss';
import { InformationEditor } from './information-editor';
import { push } from 'connected-react-router';
import { RoutingPath } from '../../../routes/routing-path';
import { IspLogOperation } from '../../../models/logs/isp-log-operation';
import { InfoParams, informationAction, InformationDefaultData } from '../../../slices/information-slice';
import { useParams } from 'react-router-dom';
import { apiIsp, apiCompany } from '../../../managers/api-manager';
import { InfomationDetailEntity } from './type/infomation.type';
import { useWillUnMount, useDidMount } from '../../../hooks/life-cycle';
import { NotificationDetaEntity } from './type/infomation.type';
import { cloneDeep } from 'lodash';
import { dialogAction } from '../../../slices/dialog-slice';
import { DateFormatter } from '../../../utilities';
import { InforMationEditDialog } from '../../dialog/infomation-edit-dialog';
import { InformationConfirmationDialog } from '../../dialog/infomation-confirmation-dialog';
import { RouteComponentProps } from 'react-router/ts4.0';

const breadcrumbList = (type: 'company' | 'isp') => [
  type === 'company' ? breadcrumbParts.company.home : breadcrumbParts.isp.home,
  type === 'company' ? breadcrumbParts.company.info : breadcrumbParts.isp.ispInfo,
  type === 'company' ? breadcrumbParts.company.infoAdd : breadcrumbParts.isp.ispInfoAdd,
];

type Props = {
  mode: 'add' | 'clone'
} & PageComponentDefaultProps

// FIXME:荒技。できればコンポーネント内で管理したい。(特定の画面への遷移時のみ変更。)
let isInit = true;

export const InformationAdd = (props: Props) => {
  const { apiManger, mode } = props;
  const dispatch = useAppDispatch();
  const id = useParams<{infoId: string}>().infoId;
  const { sendList, informationData } = useAppSelector((v) => v.information);
  const [isDisabled, setIsDisabled] = useState(true);
  const tableHead = [
    '企業名',
    '企業ユーザ名',
    'メールアドレス',
    // '開封状況',
    // '開封日',
  ];

  // ソートキーの命名
  const sortKey = {
    id: 1,
    category: 2,
    level: 3,
    title: 4,
    creatDate: 5,
    name: 6,
    status: 7,
    startDate: 8,
    endDate: 9,
  };
  // 昇降の明示
  const highlowKey = {
    high: 0,
    low: 1,
  };
  const [sortBy, setSortBy] = useState(sortKey.creatDate);
  const [highlow, setHighlow] = useState(highlowKey.low);
  const [info, setInfo] = useState<InfoParams | null>(null);
  const [detailData, setDetailData] = useState<InfomationDetailEntity | null>(null);
  const [sendDatas, setSendDatas] = useState<NotificationDetaEntity[]>([]);
  // const [isInit, setIsInit] = useState(true);

  const baseApi = useMemo(() => apiManger.type === 'company' ? apiCompany : apiIsp, [apiManger])

  const tableBody: TableElement[][] = useMemo(() => 
    sendDatas.map((v) => [
      v.company_name,
      v.display_name,
      v.email,
    ])
  , [sendDatas])

  const onClickSelect = useCallback(() => {
    console.log(info);
    info && dispatch(informationAction.setInformationData(info));
    dispatch(informationAction.setSendList(cloneDeep(sendDatas)));
    isInit = false
    // setIsInit(false)
    apiManger.type === 'company' ?
      CompanyLogOperation('informationCreateNotificationClick', () => {
        dispatch(push(`${RoutingPath.companyInfoSelect}?m=${mode}${mode === 'clone' ? `&i=${id}` : ''}`));
      }) :
      IspLogOperation('informationCreateNotificationClick', () => {
        dispatch(push(`${RoutingPath.ispInfoSelect}?m=${mode}${mode === 'clone' ? `&i=${id}` : ''}`));
      });
  }, [info, sendDatas]);

  const onClickCancel = useCallback(() => {
    apiManger.type === 'company' ?
      CompanyLogOperation('informationCreateCancelClick', () => {
        dispatch(push(RoutingPath.companyInfo));
      }) :
      IspLogOperation('informationCreateCancelClick', () => {
        dispatch(push(RoutingPath.ispInfo));
      });
  }, []);

  const onChangeDisabled = useCallback((v: boolean) => {
    setIsDisabled(!v);
  }, [isDisabled]);

  const onClickConfirm = useCallback(() => {
    if (!info) return;
    dispatch(dialogAction.push({
      title: 'インフォメーション入力内容確認',
      element: <InformationConfirmationDialog apiType={apiManger.type} info={info} sendDates={sendDatas} callback={() => {
        apiManger.type === 'company' ?
        CompanyLogOperation('informationCreateCancelClick', () => {
          dispatch(push(RoutingPath.companyInfo));
          dispatch(informationAction.setInformationData(InformationDefaultData))
        }) :
        IspLogOperation('informationCreateCancelClick', () => {
          dispatch(push(RoutingPath.ispInfo));
          dispatch(informationAction.setInformationData(InformationDefaultData))

        });  
      }} />,
      className: 'info_conf_dialog'
    }))
    // baseApi.informations().post({
    //   title: info.title,
    //   body: info.body,
    //   category: info.category,
    //   is_url: info.isUrl || undefined,
    //   url: info.isUrl ? info.url || undefined : undefined,
    //   level: info.level ? 1 : 2,
    //   notification_end_date: info.notificationEndDate ? DateFormatter.date2str(info?.notificationEndDate, 'YYYYMMDD', '-') : '',
    //   notification_start_date: info.notificationStartDate ? DateFormatter.date2str(info?.notificationStartDate, 'YYYYMMDD', '-') : '',
    //   sender_name: info.senderName,
    //   sender_list: sendDatas.map((v) => ({ company_code: v.company_code, member_id: v.member_id })),
    // })
  }, [info, sendDatas]);

  useDidMount(() => {
    setSendDatas(cloneDeep(sendList));
    dispatch(informationAction.setSendList([]));
  })

  useEffect(() => {
    if (mode === 'add') return;
    if (mode === 'clone') {
      const keys = Object.keys(informationData) as (keyof typeof informationData)[];
      const checkList: boolean[] = [];
      keys.forEach((v) => {
        if (v === 'level') {
          checkList.push(informationData[v] !== 0)
        } else {
          checkList.push(Boolean(informationData[v]));
        }
      });
      if (checkList.includes(true)) return;
      (baseApi as any).informations(id).get().then((v: any) => {
        const data = ((v?.body?.data as any)?.data) as InfomationDetailEntity | null;
        if (data) {
          setDetailData(data?.body ? {...data, body: JSON.parse(data.body)} : {...data, body: undefined});
        }
      })
    }
  }, [id]);

  useWillUnMount(() => {
    if (isInit) {
      dispatch(informationAction.setInformationData(InformationDefaultData));
    } else {
      isInit = true;
    }
  });

  return (
    <div
      id="App"
      className="information_add"
    >
      <SideBar currentPage="information-add" apiManger={apiManger.type} />
      <div className="main_cnt">
        <Header apiManger={apiManger.type} />
        <div className="inner">
          <BreadcrumbList breadcrumbList={breadcrumbList(apiManger.type)} />
          <div className="inner">
            <section className="">
              <header>
                <h2>インフォメーション新規登録</h2>
              </header>
              <div className="information_add__body">
                <InformationEditor
                  mode={mode}
                  apiManger={apiManger}
                  isDisabled={isDisabled}
                  checkValid={(bool) => {
                    onChangeDisabled(bool);
                  }}
                  callback={(info) => {
                    setInfo(info);
                  }}
                  initParam={mode === 'clone' && detailData ? detailData : undefined}
                  isInit={isInit}
                />
              </div>
            </section>
            <section className="">
              <header>
                <h2>通知先ユーザー一覧</h2>
                <div className="count">
                  <span>件数</span>
                  {tableBody.length}
                  <span>件</span>
                </div>
                <div style={{ marginLeft: 'auto' }}>
                  <Button
                    label="通知先設定"
                    onClick={onClickSelect}
                  />
                </div>
              </header>
              <div className="information_add__body">
                <Table
                  head={tableHead}
                  body={tableBody}
                  // alignList={['c', 'c', 'c', 'c', 'c']}
                  alignList={['c', 'c', 'c']}
                  setSortBy={setSortBy}
                  setHighlow={setHighlow}
                />
              </div>
            </section>
            <footer className="btn_box align_center">
              <Button
                size="large"
                color="tertiary"
                label="キャンセル"
                onClick={onClickCancel}
              />
              <Button
                size="large"
                label="確認"
                onClick={onClickConfirm}
                disabled={isDisabled || !sendDatas.length}
              />
            </footer>
          </div>
        </div>
      </div>
    </div>
  );
};