/* eslint-disable max-len */
import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Leaf, Organization } from './models';
import { OrganizationManager } from './managers/organization-manager';
// import { SvgManager } from './managers/svg-manager/svg-manager';
import iconFlag from '../../assets/images/common/icon_flag.svg';//ルート用画像
import iconEntry from '../../assets/images/common/icon_entry.svg';//登録用画像
import iconDelete from '../../assets/images/common/icon_delete.svg';//削除用画像
import iconEditS from '../../assets/images/common/icon_edit_s.svg';//変更用画像



type Props = {
  organizations: Organization[],
  onClick: (leaf: Leaf) => void,
  onEntryButtonClick: (leaf: Leaf | null) => void,
  onEditButtonClick: (leaf: Leaf) => void,
  onDeleteButtonClick: (leaf: Leaf) => void,
};

type LeafComponentProps = {
  leaf: Leaf,
  onClick: (leaf: Leaf) => void,
  onEntryButtonClick: (leaf: Leaf | null) => void,
  onEditButtonClick: (leaf: Leaf) => void,
  onDeleteButtonClick: (leaf: Leaf) => void,
  setUpdateFlag: React.Dispatch<React.SetStateAction<boolean>>,
};

const LeafComponent = (props: LeafComponentProps) => {
  const {
    leaf,
    onClick,
    onEntryButtonClick,
    onEditButtonClick,
    onDeleteButtonClick,
    setUpdateFlag
  } = props;
  // --------- useRef ---------
  const label = useRef<SVGTextElement>(null);
  // --------- useState ---------
  // labelの周りのrectのwidth
  const [labelWidth, setLabelWidth] = useState(0);
  // --------- useEffect ---------
  useEffect(() => {
    /** svgのtextのwidthによってrectを調整する処理 */
    const width = label.current?.getBBox()?.width || 0;
    setLabelWidth(leaf.level === 0 ? width + 20 : width);
    setUpdateFlag(prevState => !prevState)
  });
  //
  /**
   * leafが末尾のleafかどうか
   */
  const isLastChild = leaf.parent?.children[leaf.parent?.children.length - 1] === leaf.id;
  const color = '#4F4F4F';
  const fontColor = "#4F4F4F"
  const buttonFontColor = "#FFFFFF"
  const strokeWidth = 1;
  // - parent -
  const parentLevel = leaf.parent?.level || 0;
  const parentIndex = leaf.parent?.index || 0;
  // - rect -
  const width = 200;
  const height = 30;
  const dx = leaf.level * 30;
  const dy = leaf.index * 40;
  const rx = 2;
  const ry = 2;

  // - path pos -
  const fromPos = {
    x: parentLevel * 30 + 10,
    y: parentIndex * 40 + height,
  };
  const toPos = {
    x: dx,
    y: dy + (height / 2),
  };
  // - callback -
  // -- click --
  const callbackClickLeaf = useCallback(
    () => {
      onClick(leaf);
    },
    [leaf, onClick],
  );
  /** 登録ボタン押下時ハンドラ */
  const callbackClickEntryButton = useCallback(
    () => {
      onEntryButtonClick(leaf.parent)
    },
    [leaf, onEntryButtonClick]
  );
  /** 変更ボタン押下時ハンドラ */
  const callbackClickEditButton = useCallback(
    () => {
      onEditButtonClick(leaf);
    }, [leaf, onEditButtonClick]
  );
  /** 削除ボタン押下時ハンドラ */
  const callbackClickDeleteButton = useCallback(
    () => {
      onDeleteButtonClick(leaf);
    },
    [leaf, onDeleteButtonClick]
  );
  return (
    <g>
      {
        leaf.id.match('entry')
          ? (
            // ---------- 登録ボタン ----------
            <>
              <g
                style={{
                  cursor: 'pointer',
                }}
                transform={`translate(${dx}, ${dy})`}
                onClick={callbackClickEntryButton}
              >
                <rect
                  width={60}
                  height={height}
                  fill="rgba(95,158,160,1)"
                  rx={rx}
                  ry={ry}
                //stroke={color}
                //strokeWidth={strokeWidth}
                />
                <image
                  href={iconEntry}
                  x="7"
                  y="7"
                />
                <text
                  //stroke={buttonFontColor}
                  fill={buttonFontColor}
                  fontSize={14}
                  dy={20}
                  dx={25}
                >
                  登録
                </text>
              </g>
            </>
          )
          : (
            // ---------- 組織データ ----------
            <>
              <g
                style={{
                  cursor: 'pointer',
                }}
                transform={`translate(${dx}, ${dy})`}
                onClick={callbackClickLeaf}
              >
                <rect
                  width={labelWidth + 20}
                  height={height}
                  fill="#FFF"
                  stroke={color}
                  strokeWidth={strokeWidth}
                />
                {/* level0のleafの場合、旗のアイコンを表示 */}
                {leaf.level === 0
                  ? (
                    <image
                      href={iconFlag}
                      x="8"
                      y="6"
                    />
                  )
                  : <></>
                }
                <text
                  //stroke={color}
                  fontSize={14}
                  dy={20}
                  ref={label}
                  // level0のleafの場合、旗のアイコンのwidth分だけテキストを右に寄せる
                  dx={leaf.level === 0 ? 30 : 10}
                >
                  {`${leaf.label} (${leaf.memberCount})`}
                </text>
              </g>
              {/* ---------- 変更ボタン ---------- */}
              {leaf.level !== 0
                ? (
              <g
                style={{
                  cursor: 'pointer',
                }}
                transform={`translate(${dx + labelWidth + 10}, ${dy})`}
                onClick={callbackClickEditButton}
              >
                <rect
                  x={20}
                  width={60}
                  height={height}
                  fill="#4169e1"
                  rx={rx}
                  ry={ry}
                //stroke={color}
                //strokeWidth={strokeWidth}
                />
                <image
                  href={iconEditS}
                  x="25"
                  y="9"
                />
                <text
                  //stroke={buttonFontColor}
                  fontSize={14}
                  dy={20}
                  dx={45}
                  fill={buttonFontColor}>
                  変更
                </text>
              </g>) :
                <></>}
              { leaf.level !== 0 &&
                leaf.children.length === 1 &&
                leaf.memberCount === 0
                ? (
                  // ---------- 削除ボタン ----------
                  <g
                    style={{
                      cursor: 'pointer',
                    }}
                    // dx + width + 70 -> dx + width + (変更ボタンのwidth + 20)
                    transform={`translate(${dx + labelWidth + 80}, ${dy})`}
                    onClick={callbackClickDeleteButton}
                  >
                    <rect
                      x={20}
                      width={60}
                      height={height}
                      fill="#ff6347"
                      rx={rx}
                      ry={ry}
                    //stroke={color}
                    //strokeWidth={strokeWidth}
                    />
                    <image
                      href={iconDelete}
                      x="25"
                      y="9"
                    />
                    <text
                      //stroke={buttonFontColor}
                      fontSize={14}
                      dy={20}
                      dx={45}
                      fill={buttonFontColor}>
                      削除
                    </text>
                  </g>
                )
                : <></>}
            </>
          )
      }
      {leaf.parent && (
        <g>
          <path
            stroke={color}
            strokeWidth={strokeWidth}
            fill="none"
            d={`M ${toPos.x} ${toPos.y} L ${fromPos.x} ${toPos.y} ${isLastChild ? `L ${fromPos.x} ${fromPos.y}` : ''}`}
          />
        </g>
      )}
    </g>
  );
};

export const EditTreeView = (props: Props) => {
  const {
    organizations,
    onClick,
    onDeleteButtonClick,
    onEditButtonClick,
    onEntryButtonClick,
  } = props;
  // ---------------------------- useState ----------------------------
  const [leafList, setLeafList] = useState<Leaf[]>([]);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [updateFlag, setUpdateFlag] = useState(false);
  // ---------------------------- useRef ----------------------------
  const svgGroupRef = useRef<SVGGElement>(null);
  const svgEle = useRef<SVGSVGElement>(null);
  // ---------------------------- useEffect ----------------------------
  useEffect(() => {
    // if (!svgEle.current) {
    //   return;
    // }
    // const svgManager = new SvgManager(svgEle.current);
    // console.log(svgManager);
  }, []);
  /**
   * organizationデータをleafデータに変換する
   */
  useEffect(() => {
    const organizationManager = new OrganizationManager();
    const organizationsSetChildren = organizationManager.setChildrenProps(organizations);
    const leafListTemp = organizationManager.convertOrganizations2LeafList(organizationsSetChildren);
    const addedEntryButtonLeafList = organizationManager.addEntryButtonLeaf(leafListTemp);
    setLeafList(addedEntryButtonLeafList);
  }, [organizations]);
  useEffect(
    () => {
      if (!svgGroupRef || !svgGroupRef.current) {
        return;
      }
      const ele = svgGroupRef.current;
      const bbox = ele.getBBox();
      console.log('bbox-width', bbox.width);
      setWidth(bbox.width);
      setHeight(bbox.height);
    }, [leafList, svgGroupRef.current?.getBBox().width, svgGroupRef.current?.getBBox().height]);

  // ---------------------------- DOM ----------------------------
  return (
    <div
      style={{
        //textAlign: 'center',
        // maxHeight: '500px',
        // maxWidth: '600px',
        // overflow: 'scroll',
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox={`${-10} ${-10} ${width + 20} ${height + 20}`}
        width={`${width + 20}`}
        height={`${height + 20}`}
        ref={svgEle}
      >
        <g ref={svgGroupRef}>
          {leafList
            .map((leaf) => (
              <g key={leaf.id}>
                <LeafComponent
                  leaf={leaf}
                  onClick={onClick}
                  onEntryButtonClick={onEntryButtonClick}
                  onEditButtonClick={onEditButtonClick}
                  onDeleteButtonClick={onDeleteButtonClick}
                  setUpdateFlag={setUpdateFlag}
                />
              </g>
            ))}
        </g>
      </svg>
    </div>
  );
};
