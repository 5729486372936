import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Input } from '../../ui/input/input';
import { Button } from '../../ui/button/button';
import { useDispatch } from 'react-redux';
import { RoutingPath } from '../../../routes/routing-path';
import { push } from 'connected-react-router';
import { apiCompany, ApiManager, apiIsp } from '../../../managers/api-manager';
import { dialogAction } from '../../../slices/dialog-slice';
import { QueryParamFormatter } from '../../../utilities/query-param-formatter';
import { CheckAllValid, Validation } from '../../../managers/validation/validation';
import { useDidMount } from '../../../hooks/life-cycle';
import { ValidationFactory } from '../../../managers/validation/validation-factory';
import { PageComponentDefaultProps } from '../../../models/page-component-default-props';

const validations: { [key: string]: Validation } = {
  length256: ValidationFactory('length256'),
  requireMailConfirm: ValidationFactory('default'),
  requireMail: ValidationFactory('default'),
  checkMailConfirm: ValidationFactory('default'),
};

const PasswordReissue = (props: PageComponentDefaultProps) => {
  const { apiManger } = props;
  const dispatch = useDispatch();

  // アドレス
  const [email, setEmail] = useState('');
  const handleChangeEmail = useCallback((v) => {
    setEmail(v.replace(/[^a-zA-Z0-9!-/:-@¥[-`{-~]*$/, ''));
  }, []);
  // 確認用アドレス
  const [emailConfirm, setEmailConfirm] = useState('');
  const handleChangeEmailConfirm = useCallback((v) => {
    setEmailConfirm(v.replace(/[^a-zA-Z0-9!-/:-@¥[-`{-~]*$/, ''));
  }, []);

  // バリデーションのトータルチェック
  // const isDisabled = CheckAllValid(validations);
  const [isDisabled, setIsDisabled] = useState(CheckAllValid(validations));
  const [validFlag, setValidFlag] = useState(false);
  const emailRef = useRef<HTMLInputElement>(null);
  const emailConfirmRef = useRef<HTMLInputElement>(null);

  /**
   * キャンセルボタン押下処理
   */
  const handleClickChancel = useCallback(
    () => {
      const path = apiManger.type === 'company'
       ? RoutingPath.apiCompanyLogin
       : RoutingPath.apiIspLogin;
      dispatch(push(path));
    },
    [],
  );

  /**
   * パスワード再設定要請メール送信
   */
  const handleClickTransmission = useCallback(
    () => {
      const path = apiManger.type === 'company'
       ? RoutingPath.apiCompanyLogin
       : RoutingPath.apiIspLogin;
      const api = apiManger.type === 'company'
       ? apiCompany
       : apiIsp;
       const pass = apiManger.type === 'company'
       ? '#/company/login/password/reset/'
       : '#/isp/login/password/reset/'

      setIsDisabled(true)
      api.password().reset().post({
        email: email,
        pass,
      }).then((res) => {
        if (res.header.status_code === 200) {
          dispatch(dialogAction.pushSendMail({
            message: [
              'ご指定のメールアドレスにパスワード',
              '再発行メールを送信しました。',
              'メールをご確認ください。',
            ],
            callback: () => {
              // ログイン画面遷移
              dispatch(push(path));
            },
          }));
        }
      }).catch((e) => {
        ApiManager.errorFunc(e);
        setIsDisabled(false)
      });
    },
    [email],
  );

  /** メールのバリデーション監視 */
  const checkValidMailInput = useCallback(() => {
    if (!emailRef.current ||
      !emailConfirmRef.current ||
      emailConfirmRef.current.value.length <= 0) {
      return;
    }
    emailConfirmRef.current.focus();
    emailConfirmRef.current.blur();
    emailRef.current.focus();
  }, [email]);
  useEffect(() => {
    if (validFlag) {
      checkValidMailInput();
    }
  }, [email]);
  useEffect(() => {
    setIsDisabled(CheckAllValid(validations));
  }, [
    email,
    emailConfirm,
    validFlag,
  ]);

  /** バリデーション更新 */
  useDidMount(() => {
    validations['length256'] = ValidationFactory('length256');
    validations['requireMail'] = ValidationFactory('require');
    validations['requireMailConfirm'] = ValidationFactory('require');
    validations['checkMailConfirm'] = new Validation({
      test: (v: string) => v === emailRef.current?.value,
      errorMessages: ['メールアドレスが一致しません'],
    });
    setValidFlag(true);
  });

  return (
    <>
      <div className="dialog_wrap direct_access password">
        <div className="dialog">
          <header>パスワード再発行
            <div className="text_box">
              下記にB-LOOPに登録済みのメールアドレスを入力してください
            </div>
          </header>
          <div className="dialog_body">
            <div className="edit_wrap">
              <div className="item_wrap">
                <div className="item_head">
                  ログインID（メールアドレス）
                  <span className="required">必須</span>
                </div>
                <div className="item_cnt">
                  <Input
                    value={email}
                    onChange={(e) => handleChangeEmail(e.target.value)}
                    validations={[
                      validations.requireMail,
                      validations.length256,
                    ]}
                    ref={emailRef}
                  />
                </div>
              </div>
              <div className="item_wrap">
                <div className="item_head">
                  再入力（メールアドレス）
                  <span className="required">必須</span>
                </div>
                <div className="item_cnt">
                  <Input
                    value={emailConfirm}
                    onChange={(e) => handleChangeEmailConfirm(e.target.value)}
                    validations={[
                      validations.requireMailConfirm,
                      validations.length256,
                      validations.checkMailConfirm,
                    ]}
                    ref={emailConfirmRef}
                  />
                </div>
              </div>
            </div>

            <footer>
              <Button
                size="large"
                color="tertiary"
                label="キャンセル"
                onClick={handleClickChancel}
              />
              <Button
                size="large"
                label="送信"
                onClick={handleClickTransmission}
                disabled={isDisabled}
              />
            </footer>
          </div>
        </div>
      </div>
    </>
  );
};

export default PasswordReissue;
