import { ApiGetCompaniesResponse } from 'isp_cloud_api/dist/api/isp-web/companies/api-get-companies.response';
import {
  ApiGetCompanyMembersResponse,
} from 'isp_cloud_api/dist/api/company-web/members/api-get-company-members.response';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { apiCompany, apiIsp, ApiManager } from '../../../managers/api-manager';
import { breadcrumbParts } from '../../../models/breadcrumb-parts';
import { CompanyData } from '../../../models/company-data';
import { PageComponentDefaultProps } from '../../../models/page-component-default-props';
import { BreadcrumbList } from '../../ui/breadcrumb-list/breadcrumb-list';
import { Button } from '../../ui/button/button';
import { Checkbox } from '../../ui/checkbox/checkbox';
import { DatePicker } from '../../ui/date-picker/date-picker';
import { Header } from '../../ui/header/header';
import { LabeledForm } from '../../ui/input/labeled-form';
import { Select } from '../../ui/select/select';
import { SideBar } from '../../ui/sidebar/sidebar';
import { ReactSelect, SelectInfoData, ChangeLabelData } from '../../ui/select/react-select';
import { useDidMount } from '../../../hooks/life-cycle';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { CompanyUserDetail } from '../../../models/company-user-data';
import { Radiobutton } from '../../ui/radiobutton/radiobutton';
import './operation-log.scss';
import { cloneDeep } from 'lodash';
import { dialogAction } from '../../../slices/dialog-slice';
import { MultiValue, SingleValue } from 'react-select';
import { Input } from '../../ui/input/input';
import { DateFormatter, ZeroPadding } from '../../../utilities';
import { ValidationFactory } from '../../../managers/validation/validation-factory';
import { CheckAllValid, Validation } from '../../../managers/validation/validation';
import deleteIcon from '../../../assets/images/common/icon_delete.svg';
import iconCalendarOff from '../../../assets/images/common/icon_calendar_off.svg';
import iconCalendarOn from '../../../assets/images/common/icon_calendar_on.svg';
import crossOff from '../../../assets/images/common/cross_off.svg';
import crossOn from '../../../assets/images/common/cross_on.svg';
import { useAutoSelectLogOperation } from '../../../hooks/use-async-log-operation';

export type SelectData = {
  key: string,
  label: string,
};

const ispBreadcrumbList = [
  breadcrumbParts.isp.home,
  breadcrumbParts.isp.ispLog,
];

const companyBreadcrumbList = [
  breadcrumbParts.company.home,
  breadcrumbParts.company.ispLog,
];

// バリデーション用意
const validations: { [key: string]: Validation } = {
  requireCompany: ValidationFactory('require'),
  checkAfterTodayStartDate: ValidationFactory('require'),
  checkAfterTodayEndDate: ValidationFactory('require'),
  checkStartThanPastDate: ValidationFactory('require'),
  checkDifferenceDate: ValidationFactory('require'),
};

export const OperationLog = (props: PageComponentDefaultProps) => {
  const { apiManger } = props;
  const breadcrumbList = apiManger.type === 'isp' ? ispBreadcrumbList : companyBreadcrumbList;
  const userNameDisplaySwitch = apiManger.type === 'isp';
  const { companyUserInfo } = useAppSelector((state) => state.loginCompanyUserInfo);

  /* Hooks */
  const dispatch = useAppDispatch();
  const logMang = useAutoSelectLogOperation(apiManger.type);
  /* State */
  /* ---- 検索 ---- */
  /* システム選択 */
  const [systemSelectIsCheck, setSystemSelectIsCheck] = useState(true);
  /* プロダクト選択 */
  const [cadectIsCheck, setCadectlIsCheck] = useState(false);
  const [stabrolIsCheck, setStabroIsCheck] = useState(false);
  /* 選択企業　*/
  const [company, setCompany] = useState('');
  /* 選択ユーザー */
  const [userMailBody, setUserMailBody] = useState<SingleValue<SelectInfoData> | undefined>(undefined);
  /* 日付　*/
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  /* ログ開始日 */
  const [startLogDate, setStartLogDate] = useState<Date | null>(null);
  /* -------------- */

  /* 各種リスト */
  const [companyInfoList, setCompanyInfoList] = useState<CompanyData[]>([]);
  const [userList, setUserList] = useState<CompanyUserDetail[]>([]);
  /* DLボタン制御 */
  const [isDisabled, setIsDisabled] = useState(true);
  const [validFlag, setValidFlag] = useState(false);
  // バリデーションのトータルチェック
  const [checkValidation, setCheckValidation] = useState(CheckAllValid(validations));
  /* Memo */
  /* Ref */
  const startRef = useRef<HTMLInputElement>(null);
  const endRef = useRef<HTMLInputElement>(null);
  /* Title */
  const titleText = useMemo(() => apiManger.type === 'isp' ? '企業操作ログ' : '操作ログ', [apiManger.type]);
  /* - 企業プルダウンリスト - */
  const viewList = useMemo<SelectData[]>(() => {
    const companyList: CompanyData[] = [];
    companyInfoList.forEach((v) => {
      if (v.owner_id && v.log_use_count) companyList.push(v);
    });
    return companyList.map((v, i) => ({ key: v.company_code, label: v.company_name }));
  }, [companyInfoList]);
  /* - ユーザーリスト - */
  const mailList = useMemo<SelectInfoData[]>(() => {
    return userList.map((v, i) => ({ value: v.member_id, label: v.email }));
  }, [userList]);
  /* - ユーザーリスト メールから名前に変更用の値 - */
  const userInfoList = useMemo<ChangeLabelData[]>(() => {
    return userList.map((v, i) => ({ value: v.member_id, label: v.email, name: v.display_name }));
  }, [userList]);

  const changeDate = useMemo<string>(() => {
    if (!endDate || !startLogDate) return DateFormatter.date2str(new Date(), 'YYYYMMDD', '-');
    const cloneDate = cloneDeep(endDate);
    cloneDate.setFullYear(cloneDate.getFullYear() - 3);
    const result = cloneDate < startLogDate ? startLogDate : cloneDate;

    return DateFormatter.date2str(result, 'YYYYMMDD', '-');
  }, [endDate]);

  // バリデーション用登録時の日付
  const now = useMemo(() => {
    return new Date();
  }, []);
  const year = now.getFullYear();
  const month = now.getMonth() + 1;
  const day = now.getDate();

  const todayValid = useMemo(() => {
    return String(year) + ZeroPadding(month, 2) + ZeroPadding(day, 2);
  }, [year, month, day]);

  // - ソートキーの命名 -
  const sortKey = {
    code: 1,
    name: 2,
    storage: 3,
    createdAt: 4,
    stopAt: 5,
  };
  // - 昇降の明示 -
  const highlowKey = {
    high: 0,
    low: 1,
  };
  //  -企業一覧取得用 -
  const companyListKey = {
    is_view: true,
    sort_by: sortKey.createdAt,
    highlow: highlowKey.low,
    limit: 99999,
  };
  // - ユーザー一覧取得用 -
  const memberListKey = {
    is_retirement: true,
    sort_by: sortKey.createdAt,
    highlow: highlowKey.low,
    limit: 99999,
  };

  // - 日付のファーマット -
  const formatDate = (v: Date) => (v.getFullYear() + '-' + (v.getMonth() + 1) + '-' + v.getDate());
  const termDay = (s: string, e: string): number => {
    const start = DateFormatter.str2dateNoTime(s);
    const end = DateFormatter.str2dateNoTime(e) ?? DateFormatter.str2dateNoTime(formatDate(now));
    if (!start || !end) return 0;
    return (Number(end) - Number(start)) / 86400000;
  };
  // const format = (v: string) => (v.getFullYear() + "-" + (v.getMonth() + 1) + "-" + v.getDate());
  // // - 日付のファーマット csvファイル名用 -
  // const formatDateCsv = (v: Date) => (`${v.getFullYear()}` + `${('0' + (v.getMonth() + 1)).slice(-2)}` + `${('0' + v.getDate()).slice(-2)}`);

  /* callback */
  /* - 企業詳細取得 - */
  const getCompanyLogDate = useCallback(async(v: string) => {
    const companyCode = companyInfoList.find((v2) => v2.company_code === v)?.company_code;
    if (!companyCode) return;
    const comp = await apiIsp.companies(companyCode).get();
    const data = comp.body.data as any;
    return new Date(data.log_start_date);
  }, [companyInfoList])
  // - 企業選択 -
  const changeCompany = useCallback(async(v: string) => {
    setCompany(v);
    setUserMailBody({ value: '', label: '' });
    setStartDate(null);
    setEndDate(null);
    const date = await getCompanyLogDate(v);
    if (!date) return;
    setStartLogDate(date);
  }, [viewList, userMailBody, getCompanyLogDate]);

  // - ユーザー選択 -
  const changeUser = useCallback((v) => {
    setUserMailBody(v);
  }, [mailList]);

  // - DLボタン -
  const onClickDL = useCallback(() => {
    logMang('logManagerDlClick');
    const _company = companyInfoList.find((v) => v.company_code === company);
    const _user = userList.find((v) => v.member_id === userMailBody?.value && v.company_code === company);
    if (apiManger.type === 'isp') {
      (apiIsp.logs().operations().company().download().get({
        extract_date_start: startDate ? DateFormatter.date2str(new Date(startDate), 'YYYYMMDD', '-') : changeDate,
        extract_date_end: endDate ? DateFormatter.date2str(new Date(endDate), 'YYYYMMDD', '-') : DateFormatter.date2str(new Date(), 'YYYYMMDD', '-'),
        company_code: _company?.company_code ?? '',
        member_id: _user ? _user.member_id : undefined,
        is_stabro: stabrolIsCheck && !systemSelectIsCheck,
        is_cadect: cadectIsCheck && !systemSelectIsCheck,
        kind: !systemSelectIsCheck,
      }) as Promise<any>)
      .then((v) => {
        const a = document.createElement('a');
        const url = URL.createObjectURL(v);
        // const companyName = apiManger.type === 'isp' ? companyInfoList[Number(company)].company_name : companyUserInfo.company_name;
        // const userName = userMailBody?.value ? '_' + `${userList[Number(userMailBody?.value)].display_name}` : '';
        // a.download = `${formatDateCsv(new Date) + '_' + companyName + userName }.csv`;
        a.download = '操作ログ.csv';
        a.href = url;
        a.click();
        URL.revokeObjectURL(url);
        a.remove();
      }).catch((error) => {
        ApiManager.errorFunc(error);
      });
    } else {
      (apiCompany.logs().operations().download().get({
        extract_date_start: startDate ? DateFormatter.date2str(new Date(startDate), 'YYYYMMDD', '-') : changeDate,
        extract_date_end: endDate ? DateFormatter.date2str(new Date(endDate), 'YYYYMMDD', '-') : DateFormatter.date2str(new Date(), 'YYYYMMDD', '-'),
        member_id: _user ? _user.member_id : undefined,
        is_stabro: stabrolIsCheck && !systemSelectIsCheck,
        is_cadect: cadectIsCheck && !systemSelectIsCheck,
        kind: !systemSelectIsCheck,
      }) as Promise<any>)
      .then((v) => {
        const a = document.createElement('a');
        const url = URL.createObjectURL(v);
        // const companyName = apiManger.type === 'isp' ? companyInfoList[Number(company)].company_name : companyUserInfo.company_name;
        // const userName = userMailBody?.value ? '_' + `${userList[Number(userMailBody?.value)].display_name}` : '';
        // a.download = `${formatDateCsv(new Date) + '_' + companyName + userName }.csv`;
        a.download = '操作ログ.csv';
        a.href = url;
        a.click();
        URL.revokeObjectURL(url);
        a.remove();
      }).catch((error) => {
        ApiManager.errorFunc(error);
      });
    }
  }, [titleText, systemSelectIsCheck, cadectIsCheck, stabrolIsCheck, viewList, mailList, userMailBody, startDate, endDate, apiManger, logMang]);

  const sortInitialize = useCallback(() => {
    setSystemSelectIsCheck(true);
    setCadectlIsCheck(false);
    setStabroIsCheck(false);
    if (apiManger.type !== 'company') {
      setCompany('');
      setUserList([]);
      setStartLogDate(null);
    }
    setUserMailBody(undefined);
    setStartDate(null);
    setEndDate(null);
  }, [apiManger.type]);

  /* 検索条件削除 */
  const onClickSortDelete = useCallback(() => {
    sortInitialize();
  }, []);

  // - 指定開始日 -
  const changeStartDate = useCallback((v) => {
    setStartDate(v);
    window.setTimeout(() => {
      if (endRef.current) {
        endRef.current.focus();
        endRef.current.blur();
      }
    }, 0)
  }, [endRef]);
  // - 指定終了日 -
  const changeEndDate = useCallback((v) => {
    setEndDate(v);
    window.setTimeout(() => {
      if (startRef.current) {
        startRef.current.focus();
        startRef.current.blur();
      }
    }, 0)
  }, [startRef]);

  /* Effect */
  // - 企業選択 一覧取得 -
  useDidMount(() => {
    if (apiManger.type === 'isp') {
      (apiIsp.companies().get(companyListKey) as Promise<ApiGetCompaniesResponse>)
        .then((v) => {
          if (v.body.total < companyListKey.limit) {
            setCompanyInfoList(v.body.data as any);
          } else {
            companyListKey.limit = v.body.total;
            (apiIsp.companies().get(companyListKey) as Promise<ApiGetCompaniesResponse>)
              .then((v) => {
                setCompanyInfoList(v.body.data as any);
              });
          }
        });
      delete validations.requireUser;
    } else {
      apiCompany.companies().get().then((v) => setStartLogDate(new Date(v.body.data.log_start_date)));
      delete validations.requireCompany;
    }

    validations['checkAfterTodayStartDate'] = new Validation({
      test: (v: string) =>
        v === '' || Number(v.replace(/-/g, '')) <= Number(todayValid),
      errorMessages: ['今日以降の設定はできません'],
    });
    validations['checkAfterTodayEndDate'] = new Validation({
      test: (v: string) =>
        v === '' || Number(v.replace(/-/g, '')) <= Number(todayValid),
      errorMessages: ['今日以降の設定はできません'],
    });
    setValidFlag(true);
  });

  // - ユーザー名 一覧取得 -
  useEffect(() => {
    if (apiManger.type === 'isp' && companyInfoList.length && company) {
      const companyCode = companyInfoList.find((v) => v.company_code === company)?.company_code;
      if (!companyCode) return;
      (apiIsp.companies(companyCode).members().get(memberListKey) as Promise<ApiGetCompanyMembersResponse>)
        .then((v) => {
          if (v.body.total < memberListKey.limit) {
            setUserList(v.body.data as any);
          } else {
            memberListKey.limit = v.body.total;
            (apiIsp.companies(companyCode).members().get(memberListKey) as Promise<ApiGetCompanyMembersResponse>)
              .then((v) => {
                setUserList(v.body.data as any);
              });
          }
        });
      return;
    }

    if (apiManger.type === 'company') {
      (apiCompany.members().get(memberListKey) as Promise<ApiGetCompanyMembersResponse>)
        .then((v) => {
          if (v.body.total < memberListKey.limit) {
            setUserList(v.body.data as any);
          } else {
            memberListKey.limit = v.body.total;
            (apiCompany.members().get(memberListKey) as Promise<ApiGetCompanyMembersResponse>)
              .then((v) => {
                setUserList(v.body.data as any);
              });
          }
        });
    }
  }, [company]);

  useEffect(() => {
    if (!startRef.current || !endRef.current) return;
    validations['checkStartThanPastDate'] = new Validation({
      test: (v: string) =>
        v === '' || Number(startRef.current!.value.replace(/-/g, '')) <= Number(v.replace(/-/g, '')),
      errorMessages: ['指定開始日より過去日の設定はできません'],
    });
    validations['checkDifferenceDate'] = new Validation({
      test: (v: string) => (() => {
        return (v === '' || (365 * 3) > termDay(v, endRef.current!.value.replace(/-/g, '-')))})(),
      errorMessages: ['指定期間は3年以上の設定はできません'],
    });
  }, [validFlag]);

  useEffect(() => {
    setCheckValidation(CheckAllValid(validations));
  }, [startDate, endDate, company, userMailBody]);

  useEffect(() => {
    const condition = apiManger.type === 'isp' ? company : true;
    if ((condition && startDate && !checkValidation) || (condition && endDate && !checkValidation)) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [startDate, endDate, userMailBody, company, checkValidation]);

  return (
    <div
      id="App"
      className="operation_log"
    >
      <SideBar
        currentPage="log-Management"
        apiManger={apiManger.type}
      />
      <div className="main_cnt">
        <Header apiManger={apiManger.type} />
        <div className="inner">
          <BreadcrumbList breadcrumbList={breadcrumbList} />
          <div className="inner">
            <section className="">
              <header>
                <h2>{titleText}</h2>
              </header>
              <div className="operation_log__body__wrap">
                <div className="operation_log__body">
                  {<div className="operation_log__body__row row_forms">
                    <LabeledForm
                      label="システム選択"
                      formEle={
                        <div className="forms">
                          <Radiobutton
                            label="管理サイト"
                            onClick={() =>
                              setSystemSelectIsCheck(!systemSelectIsCheck)
                            }
                            name={'systemAdmin'}
                            checked={systemSelectIsCheck}
                          />
                          <Radiobutton
                            label="ポータル"
                            onClick={() =>
                              setSystemSelectIsCheck(!systemSelectIsCheck)
                            }
                            name={'systemPortal'}
                            checked={!systemSelectIsCheck}
                          />
                        </div>
                      }
                    />
                    <Button
                      className="delete_btn"
                      label=""
                      onClick={onClickSortDelete}
                      color="quaternary"
                      icon={<img src={deleteIcon} />}
                      size="large"
                      title="入力内容をリセット"
                    />

                  </div>}
                  {userNameDisplaySwitch &&
                    <div className="operation_log__body__row">
                      <LabeledForm
                        label="企業選択"
                        formEle={<Select
                          value={company}
                          list={viewList}
                          onChange={(v) => changeCompany(v as string)}
                          placeholder=" "
                          validations={[
                            validations.requireCompany,
                          ]}
                        />}
                      />
                    </div>}
                  {<div className="operation_log__body__row">
                    <LabeledForm
                      label="ユーザー名"
                      formEle={<ReactSelect
                        list={mailList}
                        select={userMailBody}
                        changeLabelList={userInfoList}
                        isDisabled={apiManger.type === 'isp' && !company}
                        handleChange={changeUser}
                        placeholder={(!!company || apiManger.type === 'company')  ? 'ユーザーメールアドレス' : '企業を選択してください。'}
                        noOptionsMessage={'該当するメールアドレスが存在しません。'}
                        isClearable
                        formMode={'pulldown'}
                        // validations={apiManger.type === 'company' ? [
                        //   validations.requireUser,
                        // ] : []}
                        // autoFocus={apiManger.type === 'company'}
                      />}
                      className="user_name"
                    />
                  </div>}
                  {<div className="operation_log__body__row row_forms">
                    <LabeledForm
                      label="プロダクト選択"
                      formEle={
                        <div className="forms">
                          <Checkbox
                            label="CADECT"
                            checked={cadectIsCheck}
                            onClick={() => {
                              setCadectlIsCheck(!cadectIsCheck);
                            }}
                            id="cadect"
                            disabled={systemSelectIsCheck}
                          />
                          <Checkbox
                            label="STABRO"
                            checked={stabrolIsCheck}
                            onClick={() => {
                              setStabroIsCheck(!stabrolIsCheck);
                            }}
                            id="stabro"
                            disabled={systemSelectIsCheck}
                          />
                        </div>
                      }
                    />
                  </div>}
                  <div className="operation_log__body__row row_period">
                    <div className="period_form__wrap">
                      <div className="period_form">
                        <LabeledForm
                          label="指定開始日"
                          formEle={
                            <Input
                              type="date"
                              readOnly={apiManger.type === 'isp' && !company}
                              onChange={(e) => {
                                changeStartDate(e.target.value ? new Date(e.target.value) : null)
                              }}
                              onClickClearDate={() => changeStartDate(null)}
                              value={startDate ? DateFormatter.date2str(startDate, 'YYYYMMDD', '-') : ''}
                              max={DateFormatter.date2str(new Date(), 'YYYYMMDD', '-')}
                              min={startLogDate ? DateFormatter.date2str(startLogDate, 'YYYYMMDD', '-') : undefined}
                              disabled={apiManger.type === 'isp' && !company}
                              validations={[validations.checkAfterTodayStartDate, validations.checkDifferenceDate]}
                              ref={startRef}
                            />
                            // <Input
                            //   type="date"
                            //   value={DateFormatter.date2str(new Date(startDate), 'YYYYMMDD', '-')}
                            //   onChange={(e) => changeStartDate(e.target.value)}
                            //   validations={[validations.checkStartDate, validations.checkDifferenceDate]}
                            //   ref={startRef}
                            // />
                          }
                        />
                        <div className="period_form__tilde" />
                        <LabeledForm
                          label="指定終了日"
                          formEle={
                            // <DatePicker
                            //   handleChange={changeEndDate}
                            //   startDate={endDate}
                            //   maxDate={new Date()}
                            //   disabled={apiManger.type === 'isp' && !company}
                            //   validations={[validations.checkAfterTodayEndDate, validations.checkStartThanPastDate]}
                            //   _ref={endRef}
                            // />
                            <Input
                              type="date"
                              readOnly={apiManger.type === 'isp' && !company}
                              onChange={(e) => {
                                changeEndDate(e.target.value ? new Date(e.target.value) : null)
                              }}
                              value={endDate ? DateFormatter.date2str(endDate, 'YYYYMMDD', '-') : ''}
                              onClickClearDate={() => changeEndDate(null)}
                              max={DateFormatter.date2str(new Date(), 'YYYYMMDD', '-')}
                              min={startLogDate ? DateFormatter.date2str(startLogDate, 'YYYYMMDD', '-') : undefined}
                              disabled={apiManger.type === 'isp' && !company}
                              validations={[validations.checkAfterTodayEndDate, validations.checkStartThanPastDate]}
                              ref={endRef}
                            />

                            // <Input
                            //   type="date"
                            //   value={DateFormatter.date2str(new Date(endDate), 'YYYYMMDD', '-')}
                            //   onChange={(e) => changeEndDate(e.target.value)}
                            //   validations={[validations.checkStartDate, validations.checkEndDate]}
                            //   ref={endRef}
                            // />
                          }
                        />
                      </div>
                      <div
                        className="period_form__announce"
                        style={{ opacity: apiManger.type === 'isp' && !company ? 0.5 : 1 }}>
                        ※開始日または終了日のどちらか一方は必ず設定してください
                          </div>
                    </div>
                    <Button
                      label="DL"
                      onClick={onClickDL}
                      disabled={isDisabled}
                    />
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};